import React from 'react'
import './LoadingModal.css'

import { CircularProgress, Typography } from '@mui/material'

function LoadingModal() {
    return (
        <div className="loading-modal">
            <Typography className="title">กำลังโหลด...</Typography>
            <CircularProgress />
        </div>
    )
}

export default LoadingModal