import dayjs from "dayjs";
import 'dayjs/locale/th'

const dateFormat = (val, withTime = false) => {
    let format = 'D MMMM YYYY';

    const date = new Date(val);

    if (withTime) {
        format += ' เวลา HH:mm น.';
    }

    return dayjs(date).locale('th').add(543, 'year').format(format);
}

const getDifferenceDay = (from_date, to_date) => {

    if (!from_date || !to_date) return 0;

    const fromDate = dayjs(from_date);
    const toDate = dayjs(to_date);
    
    const totalLeave = toDate.diff(fromDate, 'day');

    return totalLeave + 1;
}

const base64ImageToFile = (str, filename) => {
    // extract content type and base64 payload from original string
    const pos = str.indexOf(';base64,');
    const type = str.substring(5, pos);
    const b64 = str.substr(pos + 8);
  
    // decode base64
    const imageContent = atob(b64);
  
    // create an ArrayBuffer and a view (as unsigned 8-bit)
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);
  
    // fill the view, using the decoded base64
    for(let n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n);
    }
  
    // convert ArrayBuffer to File
    const file = new File([buffer], filename, { type });
  
    return file;
}

export {
    dateFormat,
    getDifferenceDay,
    base64ImageToFile,
}