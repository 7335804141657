import React from 'react'
import './ConfirmModal.css'

import { Button, Grid, Typography } from '@mui/material'

function ConfirmModal({ template, empName, onAccept, onDecline }) {

    const handleAcceptClick = () => onAccept();
    const handleDeclineClick = () => onDecline();

    return (
        <div className="confirm-modal">
            <Typography className="title">{template.text}</Typography>
            <Typography className="title">{empName}</Typography>
            
            <Grid 
                container 
                justifyContent="center"
                gap={1}
                className="button"
            >
                <Grid item xs={"auto"}>
                    <Button 
                        variant="contained" 
                        className="accept" 
                        onClick={handleAcceptClick}
                    >
                        ยืนยัน
                    </Button>
                </Grid>
                <Grid item xs={"auto"}>
                    <Button 
                        variant="contained" 
                        className="decline" 
                        onClick={handleDeclineClick}
                    >
                        ยกเลิก
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default ConfirmModal