import React from 'react'
import './ImagePreview.css'

import { Box, Backdrop, IconButton, Typography } from '@mui/material';
import { Clear } from '@mui/icons-material';

function ImagePreview({ image, deleteable, onDelete }) {

    const [openImage, setOpenImage] = React.useState(false);

    const handleClose = () => setOpenImage(false);
    const handleToggle = () => setOpenImage(!openImage);

    const handleDeleteClick = () => {
        onDelete(image.imagePreview);
    }

    return (
        <div className="image-preview">
            <img className="image" loading="lazy" src={image.imagePreview} alt="" onClick={handleToggle} />
            <Typography className="text">{image.originalname}</Typography>
            {
                deleteable && (
                    <IconButton className="delete" onClick={handleDeleteClick}>
                        <Clear fontSize="small" />
                    </IconButton>
                )
            }

            <Backdrop
                sx={{ color: "white", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openImage}
                onClick={handleClose}
            >
                <Box 
                    sx={{ 
                        width: { xs: "100%", sm: "auto" }, 
                        height: { xs: "auto", sm: "90vh" }
                    }
                }>
                    <img loading="lazy" width="100%" height="100%" src={image.imagePreview} alt="" />
                </Box>
            </Backdrop>
        </div>
    )
}

export default ImagePreview