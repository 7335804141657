import React, { useEffect, useState } from 'react'
import './Notification.css'

import { Box, Button, Typography } from '@mui/material'

import { dateFormat } from '../../utils' 

import EmpListDialog from '../EmpListDialog/EmpListDialog'

import useAuth from '../../hooks/useAuth'
import useUnread from '../../hooks/useUnread'

import { useLocation, useNavigate } from 'react-router-dom'

function Notification({ notification }) {

    const { auth } = useAuth();
    const { setUnread } = useUnread();
    const navigate = useNavigate();
    const location = useLocation();

    const isDetail = location.pathname.includes("detail");

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (isDetail) {

            if (!notification.id) return;
            
            setUnread(prev => prev.filter(unread => unread.id !== notification.id));

        }
    }, [notification]);

    const handleRead = () => navigate(`detail/${notification.id}`);

    const hasRead = !isDetail && notification.unread.includes(auth.id);

    return (
        <Box sx={!isDetail ? { bgcolor: hasRead && "#faf5e3" } : {}} className="notification">
            <div className="header">
                <div className="header-left">
                    <Typography className="name">{`${notification.User?.first_name} ${notification.User?.last_name}`}</Typography>
                    <Typography className="datetime">
                        {dateFormat(notification?.createdAt, true)}
                    </Typography>
                </div>

                <Box 
                    className="header-right"
                    sx={{
                        xs: { display: "flex", alignItems: "center", flexDirection: "column" }, 
                        sm: { flexDirection: "row" } 
                    }} 
                >
                    {
                        auth.role === "MGR" && (
                            <Button onClick={handleOpen}>
                                {`${notification.emps?.length} รายชื่อ`}
                            </Button>
                        )
                    }
                    {
                        !isDetail && (
                            <Button onClick={handleRead}>
                                อ่าน
                            </Button>
                        )
                    }
                </Box>
            </div>

            <div className="content">
                <Typography className={`text ${!isDetail && 'detail'}`}>{notification.text}</Typography>
            </div>
            
            {
                open && (
                    <EmpListDialog 
                        open={open} 
                        handleClose={handleClose} 
                        emps={notification.emps} 
                        unread={notification.unread} 
                    />
                )
            }
        </Box>
    )
}

export default Notification