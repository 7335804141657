import React, { useEffect, useState } from 'react'
import './LeaveDetail.css'
import logo from '../../assets/images/FUTUREWALL-ts.png'

import useAuth from '../../hooks/useAuth';
import useModal from '../../hooks/useModal'

import { Button, Divider, Grid, Typography } from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom'

import { leaveService } from '../../services/leaveService';

import { leaveTypeList } from '../../constants/displayText';
import { approveTemplate, serverErrorTemplate } from '../../constants/modalConstant';

import { dateFormat, getDifferenceDay } from '../../utils'

import CustomModal from '../../components/CustomModal/CustomModal';
import ImagePreview from '../../components/ImagePreview/ImagePreview';

const labelList = {
    empName: 'ข้าพเจ้า',
    description: 'ขอลาหยุดเนื่องจาก',
    leave_type: 'ประเภทการลา',
    from_date: 'ตั้งแต่วันที่',
}

function LeaveDetail() {

    const { auth } = useAuth();

    const { id } = useParams();
    const navigate = useNavigate();

    const {
        openModal,
        handleOpen,
        handleClose,
        contentType,
        successModalContent,
        failModalContent,
        confirmModalContent,
        modalType,
        confirmModalType,
        inputModalType,
        responseModalType,
        loadingModalType,
        isSuccess,
        responseSuccess,
        responseFail,
    } = useModal();

    const [template, setTemplate] = useState(approveTemplate);
    
    // TODO: อาจจะไม่ต้อง initial object ถ้าทำ skeleton
    const [leave, setLeave] = useState({
        User: { first_name: 'ชื่อ', last_name: 'นามสกุล' },
        description: '',
        from_date: null,
        to_date: null,
        createdAt: Date.now(),
        images: [],
        leave_type: 'VACATION',
    });

    useEffect(() => {

        let isMounted = true;

        const fetchLeaveById = async () => {

            if (isMounted) {
                loadingModalType();
                handleOpen();
            }

            try {
                const data = await leaveService.getLeaveById(id);
                console.log(data);

                if (data.result) {
                    isMounted && setLeave(data.message);
                }
                
                isMounted && handleClose();

            } catch (error) {
                console.log(error);
                if (isMounted) {
                    handleClose();
                    responseModalType();
                    setTemplate(serverErrorTemplate)
                    failModalContent();
                    responseFail();
                    handleOpen();
                }
            }
        }

        fetchLeaveById();

        return () => { isMounted = false }

    }, [id])

    const getValueFromLeave = (field) => {
        let value;

        switch (field) {
            case "empName":
                value = `${leave.User?.first_name} ${leave.User?.last_name}`;
                break;

            case "from_date":
                const toDate = dateFormat(leave.to_date);
                const fromDate = dateFormat(leave.from_date);

                value = getDifferenceDay(leave.from_date, leave.to_date) === 1 
                    ? fromDate
                    : `${fromDate} - ${toDate}`;
                break;
                
            case "leave_type":
                value = leaveTypeList.find(leaveType => leaveType.value === leave.leave_type).name;
                break;

            default:
                value = leave[field] || "-";
                break;
        }

        return value;
    }

    const goToLeaveList = () => navigate('/', { replace: true });

    const handleApproveClick = async () => {
        console.log('approve => ', leave);

        setTemplate(approveTemplate);
        confirmModalType();
        confirmModalContent();
        handleOpen();
    }

    const handleRefuseClick = () => {
        console.log('refuse => ', leave);

        setTemplate(approveTemplate);
        inputModalType();
        handleOpen();
    }

    const handleEditClick = () => navigate(`/create-leave/edit/${leave.id}`);

    const handleAcceptClick = async (status = "APPROVE", reason = null) => {
        console.log('status => ', status);
        console.log('reason => ', reason);
        handleClose();
        loadingModalType();
        handleOpen();

        try {
            const data = await leaveService.updateStatus(leave.id, status, reason);
            console.log(data);

            handleClose();
            responseModalType();

            if (data.result) {
                successModalContent();
                responseSuccess();
            } else {
                failModalContent();
                responseFail();
            }

            handleOpen();

        } catch (error) {
            console.log(error);
            handleClose();
            setTemplate(serverErrorTemplate);
            failModalContent();
            responseFail();
            handleOpen();
        }
    }

    const leaveInfo = (field) => {
        const label = labelList[field];
        const value = getValueFromLeave(field);

        return (
            <GridContainer>
                <GridLabelItem>
                    <Typography>{label}</Typography>
                </GridLabelItem>

                <GridFormItem>
                    <Typography 
                        className="value" 
                        sx={field === 'description' ? { 
                            height: "100px", 
                            overflowY: "scroll" 
                        } : {}}
                    >
                        {value}
                    </Typography>
                </GridFormItem>
            </GridContainer>
        )
    }

    const fileList = leave.images.length > 0 && (
        <div className="file-container">
            <Typography className="file-count">{leave.images.length} ไฟล์แนบ</Typography>

            <Grid 
                container 
                spacing={1}
                columns={{ xs: 4, sm: 12 }}
                width={{ xs: "100%", sm: "60%", md: "50%" }}
            >
                {
                    leave.images.map(image => (
                        <Grid key={image.filename} item xs={2} sm={4}>
                            <ImagePreview image={image} />
                        </Grid>
                    ))
                }
            </Grid>
        </div>
    )

    const actionButton = () => {

        const role = auth.role;
        const status = leave.status;

        if (status === "PENDING") {

            if (role === "MGR") {
                return (
                    <div className="action-button">
                        <Button 
                            variant="contained" 
                            className="approve" 
                            onClick={handleApproveClick}
                        >
                            อนุมัติ
                        </Button>
                        <Button 
                            variant="contained" 
                            className="refuse" 
                            onClick={handleRefuseClick}
                        >
                            ไม่อนุมัติ
                        </Button>
                    </div>
                )
            }

            if (role === "EMP") {
                return (
                    <div className="action-button">
                        <Button 
                            variant="contained" 
                            className="edit" 
                            onClick={handleEditClick}
                        >
                            แก้ไข
                        </Button>
                    </div>
                )
            }
        }
    }

    const modal = (
        <CustomModal 
            template={template}
            empName={`${leave.User?.first_name} ${leave.User?.last_name}`}
            onAccept={handleAcceptClick}
            onDecline={handleClose}
            taskAfterSuccess={goToLeaveList}
            taskAfterFailure={() => console.log('after fail')}
            useModal={{
                openModal,
                handleClose,
                isSuccess,
                contentType,
                modalType
            }} 
        />
    )

    return (
        <div className="leave-detail">

            <Grid container rowGap={1}>
                <Grid 
                    item xs={12} sm={4} 
                    sx={{ textAlign: { xs: 'center', sm: 'right' } }}
                >
                    <img src={logo} width="150" alt="logo" />
                </Grid>
                <Grid item xs={12} sm={4} className="header">
                    <Typography>ใบลา</Typography>
                    <Typography>แจ้ง ณ วันที่ {dateFormat(leave.createdAt)}</Typography>
                </Grid>
            </Grid>

            <Grid 
                container
                rowGap={3}
                sx={{ mt: { xs: 4, sm: 6 } }}
                className="leave-info"
            >
                { leaveInfo('empName') }
                { leaveInfo('description') }
                { leaveInfo('leave_type') }
                { leaveInfo('from_date') }

                <GridContainer>
                    <GridLabelItem>
                        <Typography>เป็นเวลา</Typography>
                    </GridLabelItem>

                    <Grid item xs={4}>
                        <Typography className="value">
                            {
                                leave.hours === 0 ? (
                                    <span>{getDifferenceDay(leave.from_date, leave.to_date)}</span>
                                ) : (
                                    <span>{leave.hours}</span>
                                )
                            }
                        </Typography>
                    </Grid>

                    <Grid item xs={1}>
                        <Typography align="center">
                            {
                                leave.hours === 0 ? (
                                    <span>วัน</span>
                                ) : (
                                    <span>ชั่วโมง</span>
                                )
                            }
                        </Typography>
                    </Grid>
                </GridContainer>
            </Grid>

            <Divider sx={{ marginTop: '30px', marginBottom: '16px' }} />

            { fileList }

            { actionButton() }

            { modal }
            
        </div>
    )
}

export default LeaveDetail

function GridContainer({ children }) {
    return (
        <Grid 
            container 
            item xs={12}
            alignItems="center" 
            justifyContent="center" 
            className="info"
            rowGap={1}
            columns={{ xs: 7, sm: 12 }}
        >
            {children}
        </Grid>
    )
}

function GridLabelItem({ children }) {
    return (
        <Grid item xs={5} sm={3}>
            {children}
        </Grid>
    )
}

function GridFormItem({ children }) {
    return (
        <Grid item xs={5}>
            {children}
        </Grid>
    )
}