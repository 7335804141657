import { useAxios } from "../guard/axiosConfig";

export const notificationService = {
    getNotificationByUser,
    getNotificationById,
    createNotification,
    getUnreadByUser,
}

async function getNotificationByUser() {
    const { data } = await useAxios.get('/notification/getNotificationByUser');

    return data;
}

async function getNotificationById(id) {
    const { data } = await useAxios.post('/notification/getNotificationById', { id });

    return data;
}

async function createNotification(notification) {
    const { data } = await useAxios.post('/notification/createNotification', { ...notification });

    return data;
}

async function getUnreadByUser() {
    const { data } = await useAxios.get('/notification/getUnreadByUser');

    return data;
}