import { createTheme, ThemeProvider } from '@mui/material';

import useAuth from './hooks/useAuth';

import { Navigate, Route, Routes } from 'react-router-dom';

import AuthLayout from './pages/AuthLayout/AuthLayout';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Login from './pages/Login/Login';
import PublicLayout from './pages/PublicLayout/PublicLayout';
import ResetPassword from './pages/ResetPassword/ResetPassword';

import { routes } from './constants/route';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Sarabun',
      'sans-serif'
    ].join(',')
  }
});

function App() {

  const { auth } = useAuth();

  const filterRoutes = routes.filter(route => route.role.includes(auth?.role));
  const indexRoute = filterRoutes.find(route => route.index);

  return (
    <ThemeProvider theme={theme}>
      <Routes>

        <Route element={<PublicLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="sessions/recover/:token" element={<ResetPassword />} />
        </Route>

        <Route element={<AuthLayout />}>
          
          <Route index element={<Navigate to={`/${indexRoute?.path}`} replace />} />

          {
            routes.filter(route => route.role.includes(auth?.role)).map(route => (
              <Route key={route.path} path={route.path} element={route.page} />
            ))
          }
          
          <Route path="*" element={<div>404</div>} />
        </Route>
        
        <Route path="*" element={<div>404</div>} />
        
      </Routes>
    </ThemeProvider>
  );
}

export default App;