import React, { useEffect, useState } from 'react'
import './Rule.css'

import useModal from "../../hooks/useModal";

import { Divider, Grid, Typography } from '@mui/material';

import CustomModal from '../../components/CustomModal/CustomModal'
import ImagePreview from '../../components/ImagePreview/ImagePreview';

import { serverErrorTemplate } from '../../constants/modalConstant';

import { imageService } from "../../services/imageService";

function Rule() {

    const {
        openModal,
        handleOpen,
        handleClose,
        contentType,
        failModalContent,
        modalType,
        responseModalType,
        loadingModalType,
        isSuccess,
        responseFail,
    } = useModal();

    const [image, setImage] = useState(null);

    useEffect(() => {

        let isMounted = true;

        const fetchImage = async () => {

            if (isMounted) {
                loadingModalType();
                handleOpen();
            }

            try {
                const data = await imageService.getImageByType(["rule_img"]);
                console.log(data);

                if (data.result) isMounted && setImage({ ...data.message?.[0] });

                isMounted && handleClose();

            } catch (error) {
                console.log(error);
                if (isMounted) {
                    handleClose();
                    responseModalType();
                    failModalContent();
                    responseFail();
                    handleOpen();
                }
            }
        }

        fetchImage();

        return () => { isMounted = false; }
    }, []);

    return (
        <div className="rule">
            <Typography className="header">กฎการลา</Typography>
            <Divider sx={{ mt: '20px', mb: '36px' }} />

            <Grid
                container
                justifyContent="center"
            >
                <Grid item xs={10}>
                    {
                        image ? (
                            <ImagePreview image={image} />
                        ) : (
                            <Typography sx={{ width: '100%', height: '100%', textAlign: 'center' }}>ไม่มีรูปภาพ</Typography>
                        )
                    }
                </Grid>
            </Grid>

            <CustomModal 
                template={serverErrorTemplate}
                useModal={{
                    openModal,
                    handleClose,
                    isSuccess,
                    contentType,
                    modalType
                }} 
            />
        </div>
    )
}

export default Rule