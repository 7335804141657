import { useState } from "react";

const useModal = () => {
    const [openModal, setOpenModal] = useState(false);

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    const [contentType, setContentType] = useState('success');

    const successModalContent = () => setContentType('success');
    const failModalContent = () => setContentType('failure');
    const confirmModalContent = () => setContentType('confirm');

    const [modalType, setModalType] = useState('confirm');

    const confirmModalType = () => setModalType('confirm');
    const inputModalType = () => setModalType('input');
    const responseModalType = () => setModalType('response');
    const loadingModalType = () => setModalType('loading');
    
    const [isSuccess, setIsSuccess] = useState(false);

    const responseSuccess = () => setIsSuccess(true);
    const responseFail = () => setIsSuccess(false);

    return {
        openModal,
        handleOpen,
        handleClose,
        contentType,
        successModalContent,
        failModalContent,
        confirmModalContent,
        modalType,
        confirmModalType,
        inputModalType,
        responseModalType,
        loadingModalType,
        isSuccess,
        responseSuccess,
        responseFail,
    }
}

export default useModal