import React from 'react'

import { Grid, Icon, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material'
import { Lock, Person } from '@mui/icons-material';

import { roles } from '../../constants/displayText';

const formPattern = {
    username: {
        label: 'ชื่อผู้ใช้งาน',
        placeholder: 'กรุณากรอกชื่อผู้ใช้งาน',
        icon: <Person />
    },
    password: {
        label: 'รหัสผ่าน',
        type: 'password',
        placeholder: 'กรุณากรอกรหัสผ่าน',
        icon: <Lock />
    },
    confirmPassword: {
        label: 'ยืนยันรหัสผ่าน',
        type: 'password',
        placeholder: 'กรุณายืนยันรหัสผ่าน',
        icon: <Lock />
    },
    vacation_leave_hour_amount: {
        label: 'จำนวนวันลาพักร้อน',
        type: 'number',
        placeholder: 'กรุณากรอกจำนวนวันลาพักร้อน',
    },
}

function FirstStepForm({ emp, handleChange }) {

    const isPasswordNotMatch = (emp.password !== "" && emp.confirmPassword !== "") && emp.password !== emp.confirmPassword;

    const renderValue = (option) => {
        if (option === "") {
            return <span>กรุณาเลือกตำแหน่ง</span>;
        }
      
        const template = roles.find(role => role.role === option);

        return <span>{template.name}</span>;
    }

    const form = (field) => {
        const { label, type, placeholder, icon } = formPattern[field];
        const error = field === "confirmPassword" ? isPasswordNotMatch : false;

        return (
            <GridContainer>
                <GridLabelItem>
                    <Typography className="input-label">{label}:</Typography>
                </GridLabelItem>
                <GridFormItem>
                    <TextField
                        required
                        className="input-form" 
                        name={field}
                        type={type}
                        error={error}
                        placeholder={placeholder} 
                        value={emp[field]}
                        onChange={handleChange}
                        InputProps={icon && {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon sx={{ "& *": { color: "#C2C2C2" } }}>
                                        {icon}
                                    </Icon>
                                </InputAdornment>
                            ),
                        }} />
                </GridFormItem>
            </GridContainer>
        )
    }

    return (
        <Grid container rowGap={{ xs: 3, sm: 4 }}>
            { form("username") }
            { form("password") }
            { form("confirmPassword") }
            
            <GridContainer>
                <GridLabelItem>
                    <Typography className="input-label">ตำแหน่ง:</Typography>
                </GridLabelItem>
                <GridFormItem>
                    <Select
                        className="input-form" 
                        required
                        name="role"
                        displayEmpty
                        value={emp.role}
                        onChange={handleChange}
                        renderValue={renderValue}
                    >
                        <MenuItem value="" disabled>กรุณาเลือกตำแหน่ง</MenuItem>
                        {
                            roles.map(role => (
                                <MenuItem key={role.role} value={role.role}>{role.name}</MenuItem>
                            ))
                        }
                    </Select>
                </GridFormItem>
            </GridContainer>
            
            { emp.role === "EMP" && form("vacation_leave_hour_amount") }

            {
                isPasswordNotMatch && (
                    <GridContainer>
                        <Typography className="error">*รหัสผ่านไม่ตรงกัน</Typography>
                    </GridContainer>
                )
            }
        </Grid>
    )
}

export default FirstStepForm

function GridContainer({ children }) {
    return (
        <Grid 
            container 
            alignItems="center" 
            justifyContent="center" 
            rowGap={{ xs: 1 }} 
            columns={{ xs: 6, sm: 9, md: 17 }} 
        >
            {children}
        </Grid>
    )
}

function GridLabelItem({ children }) {
    return (
        <Grid item xs={4} sm={3} md={4}>
            {children}
        </Grid>
    )
}

function GridFormItem({ children }) {
    return (
        <Grid item xs={4} sm={4} md={6}>
            {children}
        </Grid>
    )
}