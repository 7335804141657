import React, { useEffect, useState } from 'react'
import './EmpListDialog.css'

import { Avatar, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { userService } from '../../services/userService'

function EmpListDialog({ open, handleClose, emps, unread }) {

    const [users, setUsers] = useState([]);

    useEffect(() => {

        let isMounted = true;

        const fetchUserByListOfId = async () => {
            try {
                const data = await userService.getUserByListOfId(emps);
                console.log(data);

                if (data.result) isMounted && setUsers([...data.message]);

            } catch (error) {
                console.log(error);
            }
        }

        fetchUserByListOfId();

        return () => { isMounted = false }

    }, [emps]);

    return (
        <Dialog fullWidth onClose={handleClose} open={open}>
            <DialogTitle className="dialog-title">
                <Typography>รายชื่อพนักงาน</Typography>
                <Typography className="seen">
                    { 
                        unread.length === 0 
                            ? "เห็นครบทุกคนแล้ว" 
                            : unread.length === emps.length
                                ? "ยังไม่มีใครเห็น"
                                : `เห็นแล้ว ${emps.length - unread.length} คน` }
                </Typography>
            </DialogTitle>
            <List sx={{ pt: 0 }}>
                {
                    users.map(emp => (
                        <ListItem key={emp.id}>
                            <ListItemAvatar>
                                <Avatar 
                                    sx={{ 
                                        bgcolor: !unread.includes(emp.id) 
                                            ? "var(--green-color)" 
                                            : "var(--red-color)" 
                                    }}
                                >
                                    { !unread.includes(emp.id) ? <Visibility /> : <VisibilityOff /> }
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={`${emp.first_name} ${emp.last_name}`} />
                        </ListItem>
                    ))
                }
            </List>
        </Dialog>
    )
}

export default EmpListDialog