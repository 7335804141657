import React, { useEffect, useState } from 'react'
import './LeaveAmountForm.css'

import useModal from '../../../hooks/useModal'

import { Button, Divider, Grid, TextField, Typography } from '@mui/material';

import CustomModal from '../../../components/CustomModal/CustomModal'

import { editConstantTemplate, serverErrorTemplate } from '../../../constants/modalConstant'
import { HOUR_PER_DAY } from '../../../constants/displayText';

import { leaveConstantService } from '../../../services/leaveConstantService';

function LeaveAmountForm() {

    const {
        openModal,
        handleOpen,
        handleClose,
        contentType,
        successModalContent,
        failModalContent,
        modalType,
        responseModalType,
        loadingModalType,
        isSuccess,
        responseSuccess,
        responseFail,
    } = useModal();

    const [template, setTemplate] = useState(editConstantTemplate);

    const [isEdit, setIsEdit] = useState(false);
    const [initialConstant, setInitialConstant] = useState({});
    const [leaveConstant, setLeaveConstant] = useState({
        personal_leave_hour_amount: 0,
        sick_leave_hour_amount: 0,
    });

    useEffect(() => {

        let isMounted = true;

        const fetchConstant = async () => {

            if (isMounted) {
                loadingModalType();
                handleOpen();
            }

            try {
                const data = await leaveConstantService.getAllConstant();
                console.log(data);

                if (data.result) {
                    const { personal_leave_hour_amount, sick_leave_hour_amount } = data.message;

                    const hoursToDays = {
                        personal_leave_hour_amount: personal_leave_hour_amount / HOUR_PER_DAY,
                        sick_leave_hour_amount: sick_leave_hour_amount / HOUR_PER_DAY,
                    }

                    if (isMounted) {
                        setLeaveConstant({ ...hoursToDays });

                        setInitialConstant({ ...hoursToDays });
                    }
                }

                isMounted && handleClose();

            } catch (error) {
                console.log(error);
                isMounted && handleClose();
            }
        }

        fetchConstant();

        return () => { isMounted = false; }
    }, []);

    const handleEdit = () => setIsEdit(true);
    const handleUnEdit = () => setIsEdit(false);
    const handleCancelChange = () => {
        handleUnEdit();
        setLeaveConstant({ ...initialConstant });
    };

    const handleChange = ({ target }) => {
        const { name, value } = target;

        setLeaveConstant({
            ...leaveConstant,
            [name]: value
        })
    }

    const handleSubmit = async () => {
        console.log(leaveConstant);
        const daysToHours = {
            personal_leave_hour_amount: leaveConstant.personal_leave_hour_amount * HOUR_PER_DAY,
            sick_leave_hour_amount: leaveConstant.sick_leave_hour_amount * HOUR_PER_DAY,
        }

        loadingModalType();
        handleOpen();
        
        try {
            const data = await leaveConstantService.updateConstant(daysToHours);
            console.log(data);
            
            handleClose();
            responseModalType();
            setTemplate(editConstantTemplate);

            if (data.result) {
                successModalContent();
                responseSuccess();
            } else {
                handleCancelChange();
                failModalContent();
                responseFail();
            }

            handleOpen();

        } catch (error) {
            console.log(error);
            handleCancelChange();
            handleClose();
            responseModalType();
            setTemplate(serverErrorTemplate);
            failModalContent();
            responseFail();
            handleOpen();
        }
    }

    return (
        <>
            <div className="leave-amount-form">
                <div className="leave-amount-form-header">
                    <Typography className="header">ตั้งค่าจำนวนวันลา</Typography>
                    { 
                        !isEdit && (
                            <Button 
                                variant="contained" 
                                className="edit" 
                                onClick={handleEdit}
                            >
                                แก้ไข
                            </Button>
                        ) 
                    }
                </div>
                <Divider sx={{ mt: '20px', mb: '36px' }} />

                <Grid
                    container
                    justifyContent="center"
                    rowGap={3}
                    className="leave-constant"
                >
                    <GridContainer>
                        <Grid item xs={5}>
                            <Typography>จำนวนวันลากิจ</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            {
                                isEdit ? (
                                    <TextField 
                                        fullWidth 
                                        type="number"
                                        className="input" 
                                        name="personal_leave_hour_amount"
                                        value={leaveConstant.personal_leave_hour_amount} 
                                        onChange={handleChange}
                                    />
                                ) : (
                                    <Typography className="text">
                                        {leaveConstant.personal_leave_hour_amount}
                                    </Typography>
                                )
                            }
                            
                        </Grid>
                        <Grid item xs={3}>
                            <Typography className="text">วัน</Typography>
                        </Grid>
                    </GridContainer>

                    <GridContainer>
                        <Grid item xs={5}>
                            <Typography>จำนวนวันลาป่วย</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            {
                                isEdit ? (
                                    <TextField 
                                        fullWidth 
                                        type="number"
                                        className="input" 
                                        name="sick_leave_hour_amount"
                                        value={leaveConstant.sick_leave_hour_amount} 
                                        onChange={handleChange}
                                    />
                                ) : (
                                    <Typography className="text">
                                        {leaveConstant.sick_leave_hour_amount}
                                    </Typography>
                                )
                            }
                        </Grid>
                        <Grid item xs={3}>
                            <Typography className="text">วัน</Typography>
                        </Grid>
                    </GridContainer>
                </Grid>

                {
                    isEdit && (
                        <div className="button">
                            <Button variant="contained" className="submit" onClick={handleSubmit}>บันทึก</Button>
                            <Button variant="contained" className="cancel" onClick={handleCancelChange}>ยกเลิก</Button>
                        </div>
                    )
                }
            </div>

            <CustomModal 
                template={template}
                taskAfterSuccess={handleUnEdit}
                taskAfterFailure={() => console.log('after fail')}
                useModal={{
                    openModal,
                    handleClose,
                    isSuccess,
                    contentType,
                    modalType
                }} 
            />
        </>
    )
}

export default LeaveAmountForm

function GridContainer({ children }) {
    return (
        <Grid 
            container 
            justifyContent="center" 
            alignItems="center" 
            item xs={11} md={9}
        >
            {children}
        </Grid>
    )
}