import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'

import Notification from '../../components/Notification/Notification'
import CustomModal from '../../components/CustomModal/CustomModal'

import useModal from '../../hooks/useModal'

import { notificationService } from '../../services/notificationService'

import { serverErrorTemplate } from '../../constants/modalConstant'

function NotificationDetail() {

    const { id } = useParams();

    const {
        openModal,
        handleOpen,
        handleClose,
        contentType,
        failModalContent,
        modalType,
        responseModalType,
        loadingModalType,
        isSuccess,
        responseFail,
    } = useModal();

    const [template, setTemplate] = useState(serverErrorTemplate);

    const [notification, setNotification] = useState({
        User: { first_name: 'ชื่อ', last_name: 'นามสกุล' },
        createdAt: Date.now(),
        text: '',
        emps: [],
    });

    useEffect(() => {

        let isMounted = true;

        const fetchNotification = async () => {
            
            if (isMounted) {
                loadingModalType();
                handleOpen();
            }

            try {
                const data = await notificationService.getNotificationById(id);
                console.log(data);

                if (data.result) {
                    if (isMounted) setNotification({ ...data.message });
                }
                
                isMounted && handleClose();

            } catch (error) {
                console.log(error);
                if (isMounted) {
                    handleClose();
                    responseModalType();
                    setTemplate(serverErrorTemplate);
                    failModalContent();
                    responseFail();
                    handleOpen();
                }
            }
        }

        fetchNotification();

        return () => { isMounted = false }

    }, [id]);

    return (
        <>
            <Notification notification={notification} />
            
            <CustomModal
                template={template}
                taskAfterFailure={() => console.log('after fail')}
                useModal={{
                    openModal,
                    handleClose,
                    isSuccess,
                    contentType,
                    modalType
                }} 
            />
        </>
    )
}

export default NotificationDetail