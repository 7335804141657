import React, { useState } from 'react'
import './ResetPassword.css'

import useModal from '../../hooks/useModal';

import { TextField, InputAdornment, Button, Typography, Grid } from '@mui/material';
import { Lock } from '@mui/icons-material'

import { useNavigate, useParams } from 'react-router-dom';

import { authService } from '../../services/authService'

import CustomModal from '../../components/CustomModal/CustomModal';

import { resetPasswordTemplate, serverErrorTemplate } from '../../constants/modalConstant';

function ResetPassword() {

    const navigate = useNavigate();
    const { token } = useParams();

    const {
        openModal,
        handleOpen,
        handleClose,
        contentType,
        successModalContent,
        failModalContent,
        modalType,
        responseModalType,
        loadingModalType,
        isSuccess,
        responseSuccess,
        responseFail,
    } = useModal();

    const [template, setTemplate] = useState(resetPasswordTemplate(""));

    const [password, setPassword] = useState({
        newPassword: "",
        confirmPassword: "",
    });

    const goToLogin = () => navigate('/', { replace: true });

    const handleSubmit = async (e) => {
        e.preventDefault();

        loadingModalType();
        handleOpen();
        
        console.log(password);
        console.log(token);
        
        const { newPassword, confirmPassword } = password;
        
        try {
            const data = await authService.resetPassword(newPassword, confirmPassword, token);
            console.log(data);

            handleClose();
            responseModalType();

            if (data.result) {
                setTemplate(resetPasswordTemplate(""));
                successModalContent();
                responseSuccess();
            } else {

                if (data.code === 401) setTemplate(resetPasswordTemplate("ลิ้งก์หมดอายุ กรุณาขออีเมลสำหรับการรีเซ็ตรหัสผ่านใหม่อีกครั้ง"));
                else setTemplate(resetPasswordTemplate("รหัสผ่านไม่ตรงกัน กรุณาทำรายการใหม่อีกครั้ง"));

                failModalContent();
                responseFail();
            }

            handleOpen();

        } catch (error) {
            console.log(error);
            handleClose();
            responseModalType();
            setTemplate(serverErrorTemplate);
            failModalContent();
            responseFail();
            handleOpen();
        }
    }

    const handleInput = (e) => {
        setPassword({
            ...password,
            [e.target.name]: e.target.value,
        });
    }

    return (
        <>
            <Grid 
                container 
                justifyContent="center"
                className="reset-password"
            >
                <Grid item xs={10} md={6} className="container">

                    <Typography className="reset-header">
                        รีเซ็ตรหัสผ่าน
                    </Typography>

                    <form className="reset-form" onSubmit={handleSubmit}>

                        <TextField 
                            required
                            variant="outlined"
                            className="input-form"
                            type="password" 
                            name="newPassword" 
                            value={password.newPassword}
                            placeholder="กรุณากรอกรหัสผ่านใหม่"
                            onChange={handleInput}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock sx={{ color: "#C2C2C2" }} />
                                    </InputAdornment>
                                ),
                            }} />
                        
                        <TextField 
                            required
                            variant="outlined"
                            className="input-form"
                            type="password" 
                            name="confirmPassword" 
                            value={password.confirmPassword}
                            placeholder="กรุณายืนยันรหัสผ่าน"
                            onChange={handleInput}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock sx={{ color: "#C2C2C2" }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <Button className="reset-button" variant="contained" type="submit">ยืนยัน</Button>

                    </form>

                </Grid>
            </Grid>

            <CustomModal 
                template={template}
                taskAfterSuccess={goToLogin}
                taskAfterFailure={() => console.log('after fail')}
                useModal={{
                    openModal,
                    handleClose,
                    isSuccess,
                    contentType,
                    modalType
                }} 
            />
        </>
    )
}

export default ResetPassword