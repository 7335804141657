import React, { useState } from 'react'
import './Login.css';
import logo from '../../assets/images/FUTUREWALL-ts.png'

import useModal from '../../hooks/useModal';
import useAuth from '../../hooks/useAuth';

import { TextField, InputAdornment, Button, Typography, Grid } from '@mui/material';
import { Person, Lock } from '@mui/icons-material'

import { Link } from 'react-router-dom';

import { authService } from '../../services/authService';

import CustomModal from '../../components/CustomModal/CustomModal';

import { loginTemplate, serverErrorTemplate } from '../../constants/modalConstant';

function Login() {

  const { setAuth } = useAuth();
  
  const {
    openModal,
    handleOpen,
    handleClose,
    contentType,
    failModalContent,
    modalType,
    responseModalType,
    loadingModalType,
    isSuccess,
    responseFail,
  } = useModal();

  const [template, setTemplate] = useState(loginTemplate);

  const [input, setInput] = useState({
    username: "",
    password: ""
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    loadingModalType();
    handleOpen();

    const { username, password } = input;

    try {
      const data = await authService.login(username, password);
      console.log(data);

      handleClose();
      responseModalType();
      setTemplate(loginTemplate);
      
      if (data.result) {
        setAuth({ ...data.message });
      } else {
        failModalContent();
        responseFail();
        handleOpen();
      }

    
    } catch (error) {
      console.log(error);
      handleClose();
      responseModalType();
      setTemplate(serverErrorTemplate);
      failModalContent();
      responseFail();
      handleOpen();
    }
  }

  const handleInput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  }

  return (
    <>
      <Grid 
        container 
        justifyContent="center"
        className="login"
      >
        <Grid 
          container 
          item xs={10} md={6} 
          justifyContent="center" 
          sx={{ p: { xs: "50px 40px", sm: "50px 60px" }}}
          className="container"
        >
          
          <Grid item xs={9} sm={6}>
              <img src={logo} width="100%" alt="" />
          </Grid>
          
          <Grid item xs={12}>
            <form className="login-form" onSubmit={handleSubmit}>

              <TextField 
                required
                variant="outlined"
                className="input-form"
                type="text" 
                name="username"
                value={input.username}
                placeholder="กรุณากรอกชื่อผู้ใช้งาน" 
                onChange={handleInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person sx={{ color: "#C2C2C2" }} />
                    </InputAdornment>
                  ),
                }} />

              <TextField 
                required
                variant="outlined"
                className="input-form"
                type="password" 
                name="password" 
                value={input.password}
                placeholder="กรุณากรอกรหัสผ่าน"
                onChange={handleInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock sx={{ color: "#C2C2C2" }} />
                    </InputAdornment>
                  ),
                }} />

              <div className="forgot-container">
                <Link to="/forgot-password">
                  <Typography className="forgot-text">ลืมรหัสผ่าน</Typography>
                </Link>
              </div>

              <Button className="login-button" variant="contained" type="submit">เข้าสู่ระบบ</Button>

            </form>
          </Grid>

        </Grid>
      </Grid>

      <CustomModal 
        template={template}
        taskAfterFailure={() => console.log('after fail')}
        useModal={{
          openModal,
          handleClose,
          isSuccess,
          contentType,
          modalType
        }} 
      />
    </>
  )
}

export default Login