import React, { useState } from 'react'
import './ForgotPassword.css';

import useModal from '../../hooks/useModal';

import { TextField, InputAdornment, Button, Typography, Grid } from '@mui/material';
import { Email } from '@mui/icons-material'

import { authService } from '../../services/authService'

import CustomModal from '../../components/CustomModal/CustomModal';

import { forgotPasswordTemplate, serverErrorTemplate } from '../../constants/modalConstant';

import { useNavigate } from 'react-router-dom';

function ForgotPassword() {

    const navigate = useNavigate();

    const {
        openModal,
        handleOpen,
        handleClose,
        contentType,
        successModalContent,
        failModalContent,
        modalType,
        responseModalType,
        loadingModalType,
        isSuccess,
        responseSuccess,
        responseFail,
    } = useModal();

    const [template, setTemplate] = useState(forgotPasswordTemplate(""));

    const [email, setEmail] = useState("");

    const goToLogin = () => navigate('/');

    const handleSubmit = async (e) => {
        e.preventDefault();

        loadingModalType();
        handleOpen();
        
        console.log(email);
        
        try {
            const data = await authService.forgotPassword(email);
            console.log(data);
            
            handleClose();
            responseModalType();

            if (data.result) {
                setTemplate(forgotPasswordTemplate(""));
                successModalContent();
                responseSuccess();
            } else {
                
                if (data.code === 404) setTemplate(forgotPasswordTemplate("ไม่พบอีเมล"));
                else setTemplate(forgotPasswordTemplate("ส่งอีเมลไม่สำเร็จ"));

                failModalContent();
                responseFail();

            }

            handleOpen();

        } catch (error) {
            console.log(error);
            handleClose();
            responseModalType();
            setTemplate(serverErrorTemplate);
            failModalContent();
            responseFail();
            handleOpen();
        }
    }

    const handleInput = (e) => {
        setEmail(e.target.value);
    }

    return (
        <>
            <Grid 
                container 
                justifyContent="center"
                className="forgot-password"
            >
                <Grid item xs={10} md={6} className="container">

                    <Typography className="forgot-header">
                        ลืมรหัสผ่าน
                    </Typography>
                    
                    <form className="forgot-form" onSubmit={handleSubmit}>

                        <TextField 
                            required
                            variant="outlined"
                            className="input-form"
                            type="email" 
                            name="email" 
                            value={email}
                            placeholder="กรุณากรอกอีเมล"
                            onChange={handleInput}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Email sx={{ color: "#C2C2C2" }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <Button className="forgot-button" variant="contained" type="submit">ยืนยันอีเมล</Button>

                    </form>

                </Grid>
            </Grid>

            <CustomModal 
                template={template}
                taskAfterSuccess={goToLogin}
                taskAfterFailure={() => console.log('after fail')}
                useModal={{
                    openModal,
                    handleClose,
                    isSuccess,
                    contentType,
                    modalType
                }} 
            />
        </>
    )
}

export default ForgotPassword