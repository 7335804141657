import React from 'react'
import './ReportList.css'

import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import { Folder, FileDownload } from '@mui/icons-material';

import { reportService } from '../../services/reportService';

const options = { 
    day: 'numeric', 
    month: 'long', 
    year: 'numeric', 
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'Asia/Bangkok'
}

function ReportList({ list }) {

    const downloadReportsByName = async (filename) => {
        console.log(filename);
        try {
            const data = await reportService.downloadReportsByName(filename);
            console.log(data);
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="report-list">
            {
                list.length === 0 ? (
                    <Typography align="center">ไม่พบไฟล์</Typography>
                ) : (
                    <List>
                        {
                            list.map((file) => (
                                <ListItem
                                    key={file.mtime}
                                    secondaryAction={
                                        <IconButton edge="end" onClick={() => downloadReportsByName(file.filename)}>
                                            <FileDownload />
                                        </IconButton>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Folder />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={file.filename.split('-')[0].concat('.xlsx')}
                                        secondary={new Intl.DateTimeFormat('th-TH', options).format(file.mtime)}
                                    />
                                </ListItem>
                            ))
                        }
                    </List>
                )
            }
        </div>
    )
}

export default ReportList