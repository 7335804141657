import { createContext, useState } from 'react'

export const UnreadContext = createContext();

function UnreadProvider({ children }) {

    const [unread, setUnread] = useState([]);

    return (
        <UnreadContext.Provider value={{ unread, setUnread }}>
            {children}
        </UnreadContext.Provider>
    )
}

export default UnreadProvider