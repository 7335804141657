import React from 'react'

import { Grid, TextField, Typography } from '@mui/material'

const formPattern = {
    first_name: {
        label: 'ชื่อ',
        placeholder: 'กรุณากรอกชื่อ',
        type: "text"
    },
    last_name: {
        label: 'นามสกุล',
        placeholder: 'กรุณากรอกนามสกุล',
        type: "text"
    },
    phone: {
        label: 'เบอร์โทรศัพท์',
        placeholder: 'กรุณากรอกเบอร์โทร',
        type: "tel"
    },
    email: {
        label: 'อีเมล',
        placeholder: 'กรุณากรอกอีเมล',
        type: "email"
    },
    address: {
        label: 'ที่อยู่',
        placeholder: 'กรุณากรอกที่อยู่',
        type: "text",
        multiline: true
    },
    vacation_leave_hour_amount: {
        label: 'จำนวนวันลาพักร้อน',
        placeholder: 'กรุณากรอกจำนวนวันลาพักร้อน',
        type: "number",
    }
}

function SecondStepForm({ emp, handleChange, isEdit }) {

    const form = (field) => {
        const { label, placeholder, type, multiline } = formPattern[field];

        return (
            <Grid item xs={6}>
                <Typography className="input-label">{label}:</Typography>
                <TextField 
                    required
                    className="input-form"
                    multiline={multiline}
                    minRows={4}
                    maxRows={4}
                    type={type}
                    name={field}
                    placeholder={placeholder} 
                    value={emp[field]}
                    onChange={handleChange} />
            </Grid>
        )
    }

    return (
        <Grid 
            container 
            alignItems="flex-start" 
            justifyContent="center" 
            rowGap={3} 
            columnGap={8}
            columns={{ xs: 8, sm: 15 }}
        >
            { form("first_name") }
            { form("last_name") }
            { form("phone") }
            { form("email") }
            { form("address") }
            {
                isEdit && emp.vacation_leave_hour_amount 
                    ? form("vacation_leave_hour_amount")
                    : <Grid item xs={6}></Grid>
            }
        </Grid>
    )
}

export default SecondStepForm