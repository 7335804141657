import React, { useEffect, useState } from 'react'
import './AuthLayout.css'

import { AppBar, Badge, Box, Container, Drawer, IconButton, Menu, MenuItem, Toolbar, Typography,} from '@mui/material';
import { KeyboardArrowDown, Menu as MenuIcon, NotificationsOutlined } from '@mui/icons-material';

import Sidebar from '../../components/Sidebar/Sidebar';

import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import { authService } from '../../services/authService';

import useAuth from '../../hooks/useAuth';
import useSocket from '../../hooks/useSocket';
import useUnread from '../../hooks/useUnread';

import { notificationService } from '../../services/notificationService';

const drawerWidth = 240;

function AuthLayout(props) {

    const { auth } = useAuth();
    const { socket, receiveNotification } = useSocket();
    const { unread, setUnread } = useUnread();

    const [mobileOpen, setMobileOpen] = useState(false);
    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

    const [notificationButton, setNotificationButton] = useState(null);
    const notificationOpen = Boolean(notificationButton);
    const handleNotificationClick = (event) => setNotificationButton(event.currentTarget);
    const handleNotificationClose = () => setNotificationButton(null);

    const [optionMenu, setOptionMenu] = useState(null);
    const optionMenuOpen = Boolean(optionMenu);
    const handleOptionMenuClick = (event) => setOptionMenu(event.currentTarget);
    const handleOptionMenuClose = () => setOptionMenu(null);

    const { window } = props;
    const container = window !== undefined ? () => window().document.body : undefined;

    useEffect(() => {
        
        const fetchUnread = async () => {
            try {
                const data = await notificationService.getUnreadByUser();
                console.log(data);
                if (data.result) setUnread([...data.message]);
            } catch (error) {
                console.log(error);
            }
        }

        auth && auth?.role === "EMP" && fetchUnread();

    }, [auth]);

    useEffect(() => {

        if (auth?.role === "EMP") {

            if (socket) {
                console.log('receive run');
                receiveNotification(notification => {
                    console.log(notification);
                    setUnread(prev => {
                        console.log(prev);
                        return [notification, ...prev]
                    })
                });
            }

        }

        return () => { auth?.role === "EMP" && socket && socket.removeAllListeners('receive-notification') }
        
    }, [socket])

    const mobileDrawer = (
        <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
            sx={{
                display: { sm: 'block', md: 'none' },
                '& .MuiDrawer-paper': { 
                    boxSizing: 'border-box', 
                    width: drawerWidth 
                },
            }}
        >
            <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
        </Drawer>
    );

    const desktopDrawer = (
        <Drawer
            open
            variant="permanent"
            sx={{
                display: { xs: 'none', sm: 'none', md: 'block' },
                '& .MuiDrawer-paper': { 
                    boxSizing: 'border-box', 
                    width: drawerWidth, 
                    borderRight: 0, 
                },
            }}
        >
            <Sidebar />
        </Drawer>
    );
  
    if (!auth) {

        return <Navigate to="/login" replace />

    } else {

        return (
            <Box className="auth-layout">

                <AppBar
                    position="fixed"
                    className="appbar"
                    sx={{
                        width: { md: `calc(100% - ${drawerWidth}px)` },
                        ml: { md: `${drawerWidth}px` },
                    }}
                >
                    <Toolbar>
                        <IconButton
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { md: 'none' }, color: 'var(--main-text-color)' }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                    
                    <Toolbar className="user-option">
                        {
                            auth.role === "EMP" && (
                                <IconButton edge="start" onClick={handleNotificationClick}>
                                    <Badge badgeContent={unread.length} color="error">
                                        <NotificationsOutlined />
                                    </Badge>
                                </IconButton>
                            )
                        }

                        <IconButton 
                            edge="end" 
                            sx={{ borderRadius: "6px" }}
                            onClick={handleOptionMenuClick}
                        >
                            <Typography>{`${auth.first_name} ${auth.last_name}`}</Typography>
                            <KeyboardArrowDown />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Box
                    component="nav"
                    sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
                >
                    
                    {mobileDrawer}

                    {desktopDrawer}

                </Box>

                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, width: `calc(100% - ${drawerWidth}px)` }}
                >
                    <Toolbar sx={{ height: 75 }} />

                    <Container maxWidth="xl" className="content-container">
                        <Outlet />
                    </Container>

                </Box>

                <NotificationDropDown
                    unread={unread}
                    anchorEl={notificationButton} 
                    optionMenuOpen={notificationOpen} 
                    handleOptionMenuClose={handleNotificationClose} 
                />

                <OptionMenuDropDown 
                    anchorEl={optionMenu} 
                    optionMenuOpen={optionMenuOpen} 
                    handleOptionMenuClose={handleOptionMenuClose} 
                />
            </Box>
        );
    }
}

export default AuthLayout

function NotificationDropDown({ unread, anchorEl, optionMenuOpen, handleOptionMenuClose }) {
    
    const navigate = useNavigate();

    const goToNotificationDetail = (id) => navigate(`notification/detail/${id}`);

    const handleNotificationItemClick = (id) => {
        handleOptionMenuClose();
        goToNotificationDetail(id);
    }

    return (
        <Menu
            anchorEl={anchorEl}
            open={optionMenuOpen}
            onClose={handleOptionMenuClose}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            sx={{ '& *': { color: 'var(--main-text-color)' } }}
        >
            {
                unread.length === 0 ? (
                    <Typography sx={{ p: 2 }}>
                        ไม่พบการแจ้งเตือน
                    </Typography>
                ) : (
                    unread.map(notification => (
                        <MenuItem
                            key={notification.id}
                            onClick={() => handleNotificationItemClick(notification.id)}
                        >
                            <NotificationsOutlined />
                            &nbsp;
                            <Typography>
                                {`${notification.User.first_name} ${notification.User.last_name}`}
                            </Typography>
                            &nbsp;
                            <Typography sx={{ fontWeight: 'var(--light-weight)' }}>ส่งแจ้งเตือนถึงคุณ</Typography>
                        </MenuItem>
                    ))
                )
            }
        </Menu>
    )
}

function OptionMenuDropDown({ anchorEl, optionMenuOpen, handleOptionMenuClose }) {

    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();

    const handleSetting = () => {
        handleOptionMenuClose();
        navigate('/setting/leave-amount');
    }

    const handleLogout = async () => {
        handleOptionMenuClose();

        try {
          
            const data = await authService.logout();

            console.log(data);

            if (data.result) {
                setAuth(null);
                // navigate('login', { replace: true });
            }
        
        } catch (error) {
    
            console.log(error);
          
        }
    }

    return (
        <Menu
            anchorEl={anchorEl}
            open={optionMenuOpen}
            onClose={handleOptionMenuClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            { auth.role === "MGR" && <MenuItem onClick={handleSetting}>การตั้งค่า</MenuItem>}
            <MenuItem onClick={handleLogout}>ออกจากระบบ</MenuItem>
        </Menu>
    )
}