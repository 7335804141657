import { useAxios } from "../guard/axiosConfig";

export const reportService = {
    getReports,
    downloadReportsByName,
}

async function getReports() {
    const { data } = await useAxios.get('/report/getReports');

    return data;
}

async function downloadReportsByName(filename) {
    const { data } = await useAxios.post('/report/downloadReportsByName', { filename }, {
        responseType: 'blob',
    });

    return data;
}