import { useAxios } from "../guard/axiosConfig";

export const authService = {
    login,
    logout,
    forgotPassword,
    resetPassword,
}

async function login(username, password) {
    const { data } = await useAxios.post('/auth/login', { username, password });

    return data;
}

async function logout() {
    const { data } = await useAxios.get('/auth/logout');

    if (data.result) localStorage.removeItem('user');

    return data;
}

async function forgotPassword(email) {
    const { data } = await useAxios.post('/auth/forgot-password', { email });

    return data;
}

async function resetPassword(newPassword, confirmPassword, token) {
    const { data } = await useAxios.post(
        '/auth/reset-password', 
        { newPassword, confirmPassword }, 
        { params: { token } }
    );

    return data;
}