import React, { useCallback, useEffect, useState } from 'react'
import './EmployeeManagement.css'

import useModal from '../../hooks/useModal';

import { Button, Grid, TextField, Typography } from '@mui/material';

import { useGridApiContext } from '@mui/x-data-grid';

import DataTable from '../../components/DataTable/DataTable';
import CustomModal from '../../components/CustomModal/CustomModal';

import { useNavigate } from 'react-router-dom';

import { userService } from '../../services/userService'

import { deleteEmpTemplate, serverErrorTemplate } from '../../constants/modalConstant'
import { roles, HOUR_PER_DAY } from '../../constants/displayText'

function remainTextFormat(val) {
    const remain = Number(val) / HOUR_PER_DAY;

    if (!remain) return "-";

    if (remain % 1 === 0) return `${remain} วัน`;

    const days = remain < 0 ? Math.ceil(remain) : Math.floor(remain);
    const hours = (remain % 1) * HOUR_PER_DAY;
    
    let result = "";
    result += days === 0 ? "" : `${days} วัน`;
    result += hours === 0 ? "" : ` ${hours} ชั่วโมง`;

    return result;
}

const columns = [
    {
        field: 'role',
        headerName: 'ตำแหน่ง',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 1,
        minWidth: 150,
        valueFormatter: (params) => {
            const currentRole = params.value;
            const displayRole = roles.find(role => role.role === currentRole);

            return displayRole.name;
        }
    },
    {
        field: 'emp',
        headerName: 'พนักงาน',
        headerAlign: 'center',
        sortable: false,
        flex: 1,
        minWidth: 150,
        valueGetter: (params) => `${params.row.first_name} ${params.row.last_name}`
    },
    {
        field: 'vacation_remain',
        headerName: 'วันลาพักร้อน',
        headerAlign: 'center',
        type: 'number',
        align: 'center',
        sortable: false,
        flex: 1,
        minWidth: 120,
        valueGetter: (params) => remainTextFormat(params.row.leave_remain?.vacation_leave_hour_remain)
    },
    {
        field: 'personal_remain',
        headerName: 'วันลากิจ',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 1,
        minWidth: 120,
        valueGetter: (params) => remainTextFormat(params.row.leave_remain?.personal_leave_hour_remain)
    },
    {
        field: 'sick_remain',
        headerName: 'วันลาป่วย',
        type: 'number',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 1,
        minWidth: 120,
        valueGetter: (params) => remainTextFormat(params.row.leave_remain?.sick_leave_hour_remain)
    },
    {
        field: 'actions',
        type: 'actions',
        sortable: false,
        flex: 0.75,
        minWidth: 180,
        renderCell: (params) => <OptionButton emp={params.row} />
    },
];

const key = -1;

function EmployeeManagement() {

    const [controller, setController] = useState(null);

    const [filterEmp, setFilterEmp] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [emps, setEmps] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const fetchUser = useCallback(async (page, filters, key, controller) => {

        const { filterEmp } = filters || {};
        const currentFilter = { filterEmp: filterEmp || "" };
        console.log('currentFilter => ', currentFilter);
        console.log('key => ', key);

        setCurrentPage(page);
        setEmps([]);
        setIsLoading(true);

        try {

            const data = await userService.getAllUser({ page, filter: currentFilter }, controller.signal);

            console.log(data);
            
            if (data.result) {
                setEmps([...data.message]);
                setRowCount(data.rowCount);
            }

            setIsLoading(false);

        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {

        const ctrl = new AbortController();

        setController(ctrl);
        fetchUser(0, null, key, ctrl);

    }, [fetchUser])

    useEffect(() => {

        return () => { controller && controller.abort(); }

    }, [controller]);
    
    const handleChangeEmp = (e) => setFilterEmp(e.target.value);

    const handleFilterSearch = () => fetchUser(0, { filterEmp }, key, controller);

    const clearFilter = () => {
        setFilterEmp("");
        fetchUser(0, null, key, controller);
    }

    return (
        <div className="emp-manage">
            <Typography className="header">จัดการข้อมูลพนักงาน</Typography>

            <Grid 
                className="options"
                container 
                alignItems="center" 
                justifyItems="flex-start" 
                rowGap={2} 
                columnGap={3} 
            >
                <Grid item xs={12} sm={'auto'}>
                    <Typography className="text">จำนวน {rowCount} รายการ</Typography>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <TextField 
                        fullWidth 
                        className="emp-input"
                        placeholder='พนักงาน : ทั้งหมด'
                        value={filterEmp} 
                        onChange={handleChangeEmp} 
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <div className="filter-button">
                        <Button 
                            fullWidth 
                            variant="contained" 
                            className="search-button"
                            onClick={handleFilterSearch}
                        >
                            ค้นหา
                        </Button>
                        <Button 
                            fullWidth 
                            variant="contained" 
                            className="clear-button"
                            onClick={clearFilter}
                        >
                            ล้าง
                        </Button>
                    </div>
                </Grid>

            </Grid>

            <DataTable 
                currentPage={currentPage}
                rows={emps}
                rowCount={rowCount}
                columns={columns} 
                isLoading={isLoading}
                fetchFunction={fetchUser}
                filters={{ filterEmp }}
                keyCode={key}
            />
            
        </div>
    )
}

export default EmployeeManagement

function OptionButton({ emp }) {

    const navigate = useNavigate();
    const apiRef = useGridApiContext();

    const {
        openModal,
        handleOpen,
        handleClose,
        contentType,
        successModalContent,
        failModalContent,
        confirmModalContent,
        modalType,
        confirmModalType,
        responseModalType,
        loadingModalType,
        isSuccess,
        responseSuccess,
        responseFail,
    } = useModal();
    
    const [template, setTemplate] = useState(deleteEmpTemplate(""));

    const handleEditClick = () => navigate(`edit/${emp.id}`);

    const handleDeleteClick = async () => {
        console.log('delete', emp);
        
        setTemplate(deleteEmpTemplate(""));
        confirmModalContent();
        confirmModalType();
        handleOpen();
    }

    const handleAcceptClick = async () => {
        handleClose();
        loadingModalType();
        handleOpen();

        try {
            const data = await userService.deleteUser(emp.id);
            console.log(data);
            
            handleClose();
            responseModalType();

            if (data.result) { 
                successModalContent();
                responseSuccess();
            } else {

                if (data.code === 404) setTemplate(deleteEmpTemplate("ไม่พบผู้ใช้ที่ต้องการลบ"));
                
                if (data.code === 500) setTemplate(deleteEmpTemplate("ลบข้อมูลไม่สำเร็จ"));

                failModalContent();
                responseFail();
            }

            handleOpen();

        } catch (error) {
            console.log(error);
            handleClose();
            responseModalType();
            setTemplate(serverErrorTemplate)
            failModalContent();
            responseFail();
            handleOpen();
        }
    }

    const removeDeletedRow = () => {
        const rows = apiRef.current.state.rowsCache.state.rowsBeforePartialUpdates;
        const newRows = rows.filter(row => row.id !== emp.id);
        apiRef.current.setRows(newRows);
    }

    return (
        <>
            <Button 
                variant="contained" 
                className="button edit"
                onClick={handleEditClick}
            >
                แก้ไข
            </Button>
            <Button 
                variant="contained" 
                className="button delete"
                onClick={handleDeleteClick}
            >
                ลบ
            </Button>
            
            <CustomModal 
                template={template}
                empName={`${emp.first_name} ${emp.last_name}`}
                onAccept={handleAcceptClick}
                onDecline={handleClose}
                taskAfterSuccess={removeDeletedRow}
                taskAfterFailure={() => console.log('after fail')}
                useModal={{
                    openModal,
                    handleClose,
                    isSuccess,
                    contentType,
                    modalType
                }} 
            />
        </>
    )
}