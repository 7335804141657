import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import AuthProvider from './context/AuthContext';
import SocketProvider from './context/SocketContext';
import UnreadProvider from './context/UnreadContext';

import App from './App';

import { BrowserRouter } from 'react-router-dom';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

import reportWebVitals from './reportWebVitals';

import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra'
dayjs.extend(buddhistEra);

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <SocketProvider>
        <UnreadProvider>
          <LocalizationProvider 
            locale='th' 
            dateAdapter={AdapterDayjs} 
            dateFormats={{ year: 'BBBB' }}
          >
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </LocalizationProvider>
        </UnreadProvider>
      </SocketProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
