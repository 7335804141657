import React from 'react'
import './Loading.css'

import { CircularProgress } from '@mui/material'

function Loading() {
    return (
        <div className="loading">
            <div className="circle-loading">
                <CircularProgress />
            </div>
        </div>
    )
}

export default Loading