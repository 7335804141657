import React, { useEffect, useState } from 'react'
import './CreateEmployee.css'

import useModal from '../../hooks/useModal';

import { Button, Divider, Typography } from '@mui/material'

import FirstStepForm from '../../components/CreateEmployeeForm/FirstStepForm';
import SecondStepForm from '../../components/CreateEmployeeForm/SecondStepForm';
import CustomModal from '../../components/CustomModal/CustomModal';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { userService } from '../../services/userService';

import { createEmpTemplate, editEmpTemplate, serverErrorTemplate } from '../../constants/modalConstant'
import { HOUR_PER_DAY } from '../../constants/displayText';

const initialState = {
    username: "",
    password: "",
    confirmPassword: "",
    role: "",
    vacation_leave_hour_amount: 10,
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    address: ""
}

function CreateEmployee() {

    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();

    const isEdit = location.pathname.includes('edit');

    const {
        openModal,
        handleOpen,
        handleClose,
        contentType,
        successModalContent,
        failModalContent,
        confirmModalContent,
        modalType,
        confirmModalType,
        responseModalType,
        loadingModalType,
        isSuccess,
        responseSuccess,
        responseFail,
    } = useModal();

    const [template, setTemplate] = useState(() => {
        if (isEdit) return editEmpTemplate("");
        return createEmpTemplate("");
    });

    const [nextStep, setNextStep] = useState(false);
    const [emp, setEmp] = useState(initialState);

    const isFirstFormFilled = Boolean(
        emp.username && emp.password && emp.confirmPassword 
        && emp.password === emp.confirmPassword 
        && emp.role && emp.vacation_leave_hour_amount
    );

    useEffect(() => {

        let isMounted = true;

        if (isEdit) {

            const fetchUserById = async () => {

                if (isMounted) {
                    loadingModalType();
                    handleOpen();
                }

                try {
        
                    const data = await userService.getUserById(id);
        
                    console.log(data);
                    
                    if (data.result) {
                        const { username, first_name, last_name, phone, email, address, vacation_leave_hour_amount } = data.message;
    
                        if (isMounted) {
                            setEmp({
                                username,
                                first_name,
                                last_name,
                                phone,
                                email,
                                address,
                                vacation_leave_hour_amount: vacation_leave_hour_amount / HOUR_PER_DAY
                            });
                        }
                    }

                    isMounted && handleClose();
        
                } catch (error) {
        
                    console.log(error);
                    if (isMounted) {
                        handleClose();
                        responseModalType();
                        setTemplate(serverErrorTemplate)
                        failModalContent();
                        responseFail();
                        handleOpen();
                    }
        
                }
            }
            
            goToNextStep();
            fetchUserById();

        } else {

            goBack();
            setEmp(initialState);

        }

        return () => { isMounted = false }

    }, [isEdit, id]);

    const goToEmployeeManagement = () => navigate('/employee-management', { replace: true });
    const goToNextStep = () => setNextStep(true);
    const goBack = () => setNextStep(false);

    const handleChange = (e) => {
        setEmp({
            ...emp,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        console.log(emp);

        if (isEdit) setTemplate(editEmpTemplate(""));
        else setTemplate(createEmpTemplate(""));

        confirmModalType();
        confirmModalContent();
        handleOpen();
    }

    const handleAcceptClick = async () => {
        handleClose();
        loadingModalType();
        handleOpen();

        const empData = {
            ...emp,
            vacation_leave_hour_amount: emp.vacation_leave_hour_amount * HOUR_PER_DAY
        }

        if (isEdit) updateUser(empData);
        else createUser(empData);
    }

    const updateUser = async (empData) => {
        try {
            const data = await userService.updateUser(empData);
            console.log(data);

            handleClose();
            responseModalType();

            if (data.result) {
                successModalContent();
                responseSuccess();
            } else {
                
                if (data.code === 400) setTemplate(editEmpTemplate("อีเมลถูกใช้ไปแล้ว"));

                if (data.code === 500) setTemplate(editEmpTemplate("แก้ไขข้อมูลไม่สำเร็จ"));
                
                failModalContent();
                responseFail();
            }
    
            handleOpen();
            
        } catch (error) {
            console.log(error);
            handleClose();
            responseModalType();
            setTemplate(serverErrorTemplate);
            failModalContent();
            responseFail();
            handleOpen();
        }
    }
    
    const createUser = async (empData) => {
        try {
            const data = await userService.createUser(empData);
            console.log(data);

            handleClose();
            responseModalType();

            if (data.result) {
                successModalContent();
                responseSuccess();
            } else {
                
                if (data.code === 400) {
                    setTemplate(createEmpTemplate("ชื่อผู้ใช้หรืออีเมลถูกใช้ไปแล้ว"));
                } 

                if (data.code === 500) {
                    setTemplate(createEmpTemplate("สร้างบัญชีพนักงานไม่สำเร็จ"));
                }
                
                failModalContent();
                responseFail();
            }
    
            handleOpen();
            
        } catch (error) {
            console.log(error);
            handleClose();
            setTemplate(serverErrorTemplate);
            failModalContent();
            responseFail();
            handleOpen();
        }
    }

    return (
        <div className="create-emp">
            <Typography className="header">
                {isEdit ? "แก้ไขข้อมูลพนักงาน" : "สร้างบัญชีพนักงาน"}
            </Typography>
            <Divider sx={{ mt: '20px', mb: '36px' }} />

            <form className="create-emp-form" onSubmit={handleSubmit}>

                {
                    !nextStep && (
                        <>
                            <FirstStepForm emp={emp} handleChange={handleChange} />
                            <div className="button">
                                <Button
                                    disabled={!isFirstFormFilled}
                                    variant="contained" 
                                    className="go-next"
                                    onClick={goToNextStep}
                                    >
                                    ถัดไป
                                </Button>
                            </div>
                        </>
                    )
                }

                {
                    nextStep && (
                        <>
                            <SecondStepForm emp={emp} handleChange={handleChange} isEdit={isEdit} />
                            <div className="button">
                                {
                                    !isEdit && (
                                        <Button 
                                            variant="contained" 
                                            className="go-back"
                                            onClick={goBack}
                                        >
                                            ย้อนกลับ
                                        </Button>
                                    )
                                }
                                <Button 
                                    type="submit"
                                    variant="contained" 
                                    className="save"
                                >
                                    บันทึก
                                </Button>
                            </div>
                        </>
                    )
                }
                
            </form>

            <CustomModal 
                template={template}
                empName={`${emp.first_name} ${emp.last_name}`}
                onAccept={handleAcceptClick}
                onDecline={handleClose}
                taskAfterSuccess={goToEmployeeManagement}
                taskAfterFailure={() => console.log('after fail')}
                useModal={{
                    openModal,
                    handleClose,
                    isSuccess,
                    contentType,
                    modalType
                }}
            />
        </div>
    )
}

export default CreateEmployee