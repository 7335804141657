import React, { useEffect, useRef, useState } from 'react'
import './CreateLeave.css'

import dayjs from 'dayjs'

import useModal from '../../hooks/useModal'

import { 
    Button, Divider, FormControlLabel, Grid, IconButton, 
    Radio, RadioGroup, TextField, Typography 
} from '@mui/material'
import { Help } from '@mui/icons-material'

import { DesktopDatePicker } from '@mui/x-date-pickers';

import ImagePreview from '../../components/ImagePreview/ImagePreview';
import CustomModal from '../../components/CustomModal/CustomModal';
import LeaveTypeTooltip from '../../components/LeaveTypeTooltip/LeaveTypeTooltip';

import { leaveService } from '../../services/leaveService'

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { createLeaveTemplate, serverErrorTemplate } from '../../constants/modalConstant';

import { base64ImageToFile, getDifferenceDay } from '../../utils'

function CreateLeave() {

    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const hiddenFileInput = useRef(null);

    const isEdit = location.pathname.includes('edit');

    const {
        openModal,
        handleOpen,
        handleClose,
        contentType,
        successModalContent,
        failModalContent,
        confirmModalContent,
        modalType,
        confirmModalType,
        responseModalType,
        loadingModalType,
        isSuccess,
        responseSuccess,
        responseFail,
    } = useModal();

    const [template, setTemplate] = useState(createLeaveTemplate);

    const [durationType, setDurationType] = useState("day")
    const [totalLeave, setTotalLeave] = useState(0);
    const [fileError, setFileError] = useState("");
    const [leave, setLeave] = useState({
        leave_type: "VACATION",
        from_date: null,
        to_date: null,
        hours: "",
        images: [],
        description: "",
    });

    const [openFromDate, setOpenFromDate] = useState(false);
    const handleOpenFromDate = () => setOpenFromDate(true);
    const handleCloseFromDate = () => setOpenFromDate(false);

    const [openToDate, setOpenToDate] = useState(false);
    const handleOpenToDate = () => setOpenToDate(true);
    const handleCloseToDate = () => setOpenToDate(false);
    
    const mustHaveFile = Boolean(
        leave.leave_type === "SICK" && totalLeave > 2
            ? leave.images.length > 0 
            : true
    );

    useEffect(() => {

        let isMounted = true;

        if (isEdit) {
    
            const fetchLeave = async () => {

                if (isMounted) {
                    loadingModalType();
                    handleOpen();
                }

                try {
                    const data = await leaveService.getLeaveById(id);
                    console.log(data);
    
                    if (data.result) {
                        const { leave_type, status, from_date, to_date, hours, images, description } = data.message;

                        if (hours) setDurationType("hour");

                        isMounted && setLeave({
                            id,
                            leave_type,
                            status,
                            description,
                            from_date: dayjs(from_date),
                            to_date: dayjs(to_date),
                            hours: !hours ? "" : hours,
                            images: images.map(image => {
                                return {
                                    data: base64ImageToFile(image.imagePreview, image.originalname),
                                    originalname: image.originalname,
                                    imagePreview: image.imagePreview,
                                }
                            }),
                        });
                    }

                    isMounted && handleClose();

                } catch (error) {
                    console.log(error);
                    if (isMounted) {
                        handleClose();
                        responseModalType();
                        setTemplate(serverErrorTemplate)
                        failModalContent();
                        responseFail();
                        handleOpen();
                    }
                }
            }
    
            fetchLeave();
        
        }

        return () => { isMounted = false }

    }, [id]);

    useEffect(() => {

        setTotalLeave(getDifferenceDay(leave.from_date, leave.to_date));

    }, [leave.from_date, leave.to_date]);

    const goToLeaveHistory = () => navigate('/leave-history', { replace: true });

    const handleUploadClick = () => hiddenFileInput.current.click();

    const handleDeleteClick = (deleteImage) => {
        setFileError('');
        setLeave({
            ...leave,
            images: leave.images.filter(image => image.imagePreview !== deleteImage)
        })
    }

    const handleFileChange = ({ target }) => {
        const fileList = target.files;
        const fileCount = fileList.length;
        console.log(fileList);

        if (fileCount > 3 || leave.images.length + fileCount > 3) {
            setFileError('*อัปโหลดได้สูงสุด 3 ไฟล์');
            return;
        }

        setFileError('');

        let images = [];
        for (let i = 0; i < fileCount; i++) {
            const image = fileList[i];
            images.push({
                data: image,
                originalname: image.name,
                imagePreview: URL.createObjectURL(image),
            });
        }

        console.log(images);

        setLeave({
            ...leave,
            images: [...leave.images, ...images]
        });

        target.value = null;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        
        setLeave({
            ...leave,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        console.log(leave);
        setTemplate(createLeaveTemplate);
        setFileError('');

        confirmModalType();
        confirmModalContent();
        handleOpen();
    }

    const handleAcceptClick = async () => {
        handleClose();
        loadingModalType();
        handleOpen();

        const leaveData = { 
            ...leave,
            to_date: durationType === "hour" ? leave.from_date : leave.to_date,
            hours: durationType === "hour" ? leave.hours : "",
            images: leave.images.map(image => image.data),
        }

        console.log(leaveData);

        if (isEdit) updateLeave(leaveData);
        else createLeave(leaveData);
    }

    const createLeave = async (leaveData) => {
        try {
            const data = await leaveService.createLeave(leaveData);
            console.log(data);

            handleClose();
            responseModalType();

            if (data.result) {
                successModalContent();
                responseSuccess();
            } else {
                failModalContent();
                responseFail();
            }

            handleOpen();

        } catch (error) {
            console.log(error);
            handleClose();
            responseModalType();
            setTemplate(serverErrorTemplate);
            failModalContent();
            responseFail();
            handleOpen();
        }
    }

    const updateLeave = async (leaveData) => {
        try {
            const data = await leaveService.updateLeave(leaveData);
            console.log(data);

            handleClose();
            responseModalType();

            if (data.result) {
                successModalContent();
                responseSuccess();
            } else {
                failModalContent();
                responseFail();
            }

            handleOpen();

        } catch (error) {
            console.log(error);
            handleClose();
            responseModalType();
            setTemplate(serverErrorTemplate);
            failModalContent();
            responseFail();
            handleOpen();
        }
    }

    const error = (
        <>
            {
                leave.leave_type === "SICK" && totalLeave > 2 && leave.images.length === 0 && (
                    <Typography className="warning">*กรุณาแนบใบรับรองแพทย์</Typography>
                )
            }
            {
                fileError && (
                    <Typography className="warning">{fileError}</Typography>
                )
            }
        </>
    )

    const formDurationType = (
        <>
            {
                durationType === "day" && (
                    <>
                        <Typography className="label">ถึงวันที่</Typography>
                        <DesktopDatePicker
                            clearable
                            mask=""
                            inputFormat="DD MMMM BBBB"
                            open={openToDate}
                            onOpen={handleOpenToDate}
                            onClose={handleCloseToDate}
                            inputProps={{
                                placeholder: "วัน/เดือน/ปี",
                            }}
                            minDate={leave.from_date}
                            value={leave.to_date}
                            onChange={(val) => setLeave({ ...leave, to_date: val })}
                            renderInput={(params) => (
                                <TextField 
                                    required 
                                    fullWidth 
                                    className="input-form"
                                    onClick={handleOpenToDate} 
                                    {...params} 
                                />
                            )}
                        />
                    </>
                )
            }
            {
                durationType === "hour" && (
                    <>
                        <Typography className="label">จำนวนชั่วโมง</Typography>
                        <TextField
                            fullWidth
                            required
                            className="input-form"
                            name="hours"
                            type="number"
                            placeholder="ชั่วโมง"
                            value={leave.hours}
                            onChange={handleChange}
                        />
                    </>
                ) 
            }
        </>
    )
    
    return (
        <div className="create-leave">
            <Typography className="header">สร้างใบลา</Typography>
            <Divider sx={{ mt: "20px", mb: "30px" }} />

            <form onSubmit={handleSubmit}>

                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    rowGap={4}
                >
                    <GridItem>
                        <div className="with-tooltip">
                            <Typography className="label">ประเภทการลา</Typography>
                            <LeaveTypeTooltip>
                                <IconButton size="small">
                                    <Help fontSize="small" />
                                </IconButton>
                            </LeaveTypeTooltip>
                        </div>
                        <RadioGroup
                            name="leave_type"
                            value={leave.leave_type}
                            onChange={handleChange}
                            sx={{ 
                                flexDirection: { xs: "column", sm: "row" }, 
                                justifyContent: { sm: "space-around" },
                            }}
                            >
                            <FormControlLabel value="VACATION" control={<Radio />} label="ลาพักร้อน" />
                            <FormControlLabel value="PERSONAL" control={<Radio />} label="ลากิจ" />
                            <FormControlLabel value="SICK" control={<Radio />} label="ลาป่วย" />
                        </RadioGroup>
                    </GridItem>

                    <Grid item xs={9}>
                        <Typography className="label">
                            จำนวนวันลา 
                            <span className="suggestion">&nbsp;(1 วัน เท่ากับ 8 ชั่วโมง)</span>
                        </Typography>
                        
                        <RadioGroup
                            name="durationType"
                            value={durationType}
                            onChange={e => setDurationType(e.target.value)}
                            sx={{ 
                                flexDirection: { xs: "column", sm: "row" }, 
                                justifyContent: { sm: "space-around" },
                            }}
                            >
                            <FormControlLabel value="day" control={<Radio />} label="ลาเป็นวัน" />
                            <FormControlLabel value="hour" control={<Radio />} label="ลาเป็นชั่วโมง" />
                        </RadioGroup>
                    </Grid>
                    
                    <Grid container item xs={9}>
                        <Grid item xs={12} sm={5}>
                            <Typography className="label">ตั้งแต่วันที่</Typography>
                            <DesktopDatePicker
                                clearable
                                mask=""
                                inputFormat="DD MMMM BBBB"
                                open={openFromDate}
                                onOpen={handleOpenFromDate}
                                onClose={handleCloseFromDate}
                                inputProps={{
                                    placeholder: "วัน/เดือน/ปี",
                                }}
                                value={leave.from_date}
                                onChange={(val) => setLeave({ ...leave, from_date: val })}
                                renderInput={(params) => (
                                    <TextField 
                                        required 
                                        fullWidth 
                                        className="input-form"
                                        onClick={handleOpenFromDate} 
                                        {...params} 
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item sm={2}></Grid>
                        <Grid item xs={12} sm={5}>{formDurationType}</Grid>
                    </Grid>

                    <GridItem>
                        <Typography className="label">
                            เอกสารแนบ
                            <span className="suggestion">&nbsp;(สูงสุด 3 รูป)</span>
                        </Typography>
                        <div className="file-container">
                            <Button
                                disabled={leave.images.length >= 3}
                                variant="contained"
                                className="upload"
                                onClick={handleUploadClick}
                            >
                                อัปโหลดรูป
                            </Button>
                            <input
                                className="input-file"
                                ref={hiddenFileInput}
                                multiple
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange} 
                            />
                            
                            <Grid 
                                container 
                                spacing={1}
                                columns={{ xs: 4, sm: 12 }}
                                sx={{ mt: "4px" }}
                            >
                                {
                                    leave.images.map(image => (
                                        <Grid key={image.imagePreview} item xs={2} sm={4}>
                                            <ImagePreview image={image} deleteable onDelete={handleDeleteClick} />
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </div>

                        {error}

                    </GridItem>
                
                    <GridItem>
                        <Typography className="label">คำอธิบาย</Typography>
                        <TextField
                            fullWidth
                            multiline
                            minRows={4}
                            maxRows={4}
                            className="input-form"
                            name="description"
                            placeholder="ระบุคำอธิบายเพิ่มเติม..."
                            value={leave.description}
                            onChange={handleChange}
                        />
                    </GridItem>

                </Grid>

                <div className="button">
                    <Button 
                        disabled={!mustHaveFile}
                        variant="contained" 
                        type="submit" 
                        className="submit-btn"
                    >
                        ส่งใบลา
                    </Button>
                </div>
            </form>

            <CustomModal 
                template={template}
                onAccept={handleAcceptClick}
                onDecline={handleClose}
                taskAfterSuccess={goToLeaveHistory}
                taskAfterFailure={() => console.log('after fail')}
                useModal={{
                    openModal,
                    handleClose,
                    isSuccess,
                    contentType,
                    modalType
                }} 
            />
        </div>
    )
}

export default CreateLeave

function GridItem({ children }) {
    return (
        <Grid item xs={9}>
            {children}
        </Grid>
    )
}