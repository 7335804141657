import { createContext, useEffect, useState } from 'react'

import io from 'socket.io-client'

import useAuth from '../hooks/useAuth';

export const SocketContext = createContext();

function SocketProvider({ children }) {

    const { auth } = useAuth();

    const [socket, setSocket] = useState(null);

    useEffect(() => {

        const initialSocket = () => {
            const socketInstance = io('https://api.futurewallhouse.in.th', {
                transports : ['websocket'], 
                query: { 'user': auth.id },
            });
    
            setSocket(socketInstance);
        }
        
        auth && initialSocket();
        
    }, [auth])
    
    useEffect(() => {
        console.log(socket);
    }, [socket])

    const createNotification = (notification) => {
        socket.emit('create-notification', notification);
    }

    const receiveNotification = (callback) => {
        socket.on('receive-notification', callback);
    }

    return (
        <SocketContext.Provider value={{ socket, setSocket, createNotification, receiveNotification }}>
            {children}
        </SocketContext.Provider>
    )
}

export default SocketProvider
