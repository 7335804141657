import React from "react"
import './LeaveTypeTooltip.css'

import { Tooltip, Typography } from '@mui/material'

import { leaveTypeTooltip } from '../../constants/displayText'

function LeaveTypeTooltip({ children }) {
    return (
        <Tooltip
            arrow 
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            title={<TooltipContent />}
        >
            {children}
        </Tooltip>
    )
}

export default LeaveTypeTooltip

function TooltipContent() {
    return (
        <div className="tooltip">
            <div className="personal">
                <Typography>
                    <span className="label">ลากิจ</span>
                    &nbsp;{leaveTypeTooltip.personal.text}
                </Typography>
                <div className="example">
                    <Typography className="label">ตัวอย่างการลากิจ</Typography>
                    {
                        leaveTypeTooltip.personal.example.map(ex => (
                            <Typography className="example-item" key={ex}>{ex}</Typography>
                        ))
                    }
                </div>
            </div>
            <br />

            <div className="sick">
                <Typography>
                    <span className="label">ลาป่วย</span>
                    &nbsp;{leaveTypeTooltip.sick.text}
                </Typography>
            </div>
        </div>
    )
}