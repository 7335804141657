import Calendar from "../pages/Calendar/Calendar";
import CreateEmployee from "../pages/CreateEmployee/CreateEmployee";
import CreateLeave from "../pages/CreateLeave/CreateLeave";
import EmployeeManagement from "../pages/EmployeeManagement/EmployeeManagement";
import LeaveAmount from "../pages/LeaveAmount/LeaveAmount";
import LeaveDetail from "../pages/LeaveDetail/LeaveDetail";
import LeaveHistory from "../pages/LeaveHistory/LeaveHistory";
import LeaveList from "../pages/LeaveList/LeaveList";
import NotificationDetail from "../pages/NotificationDetail/NotificationDetail";
import NotificationForm from "../pages/NotificationForm/NotificationForm";
import NotificationList from "../pages/NotificationList/NotificationList";
import Rule from "../pages/Rule/Rule";
import ImageUploadForm from "../pages/Setting/ImageUploadForm/ImageUploadForm";
import LeaveAmountForm from "../pages/Setting/LeaveAmountForm/LeaveAmountForm";
import ResetLeave from "../pages/Setting/ResetLeave/ResetLeave";

export const routes = [
    {
        page: <LeaveList />,
        path: 'leave-list',
        index: true,
        role: ['MGR']
    },
    {
        page: <LeaveDetail />,
        path: 'leave-list/leave-detail/:id',
        role: ['MGR']
    },
    {
        page: <CreateEmployee />,
        path: 'create-employee',
        role: ['MGR']
    },
    {
        page: <EmployeeManagement />,
        path: 'employee-management',
        role: ['MGR']
    },
    {
        page: <CreateEmployee />,
        path: 'employee-management/edit/:id',
        role: ['MGR']
    },
    {
        page: <NotificationList />,
        path: 'notification',
        role: ['MGR', 'EMP']
    },
    {
        page: <NotificationDetail />,
        path: 'notification/detail/:id',
        role: ['MGR', 'EMP']
    },
    {
        page: <NotificationForm />,
        path: 'notification/create',
        role: ['MGR']
    },
    {
        page: <LeaveAmountForm />,
        path: 'setting/leave-amount',
        role: ['MGR']
    },
    {
        page: <ImageUploadForm />,
        path: 'setting/images',
        role: ['MGR']
    },
    {
        page: <ResetLeave />,
        path: 'setting/reset-leve',
        role: ['MGR']
    },
    {
        page: <LeaveAmount />,
        path: 'create-leave',
        index: true,
        role: ['EMP']
    },
    {
        page: <CreateLeave />,
        path: 'create-leave/create',
        role: ['EMP']
    },
    {
        page: <CreateLeave />,
        path: 'create-leave/edit/:id',
        role: ['EMP']
    },
    {
        page: <LeaveHistory />,
        path: 'leave-history',
        role: ['EMP']
    },
    {
        page: <LeaveDetail />,
        path: 'leave-history/leave-detail/:id',
        role: ['EMP']
    },
    {
        page: <Calendar />,
        path: 'calendar',
        role: ['EMP']
    },
    {
        page: <Rule />,
        path: 'rule',
        role: ['EMP']
    },
]