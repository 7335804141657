import check from '../assets/images/check.png'
import close from '../assets/images/close.png'

const deleteEmpTemplate = (errorMessage) => {
    return {
        success: {
            text: 'ลบข้อมูลสำเร็จ',
            img: check,
        },
        failure: {
            text: `${errorMessage} กรุณาทำรายการใหม่อีกครั้ง`,
            img: close,
            isMultiline: true
        },
        confirm: {
            text: 'ยืนยันการลบพนักงาน',
        }
    }
}

const notificationTemplate = {
    success: {
        text: 'แจ้งเตือนพนักงานสำเร็จ',
        img: check,
    },
    failure: {
        text: 'แจ้งเตือนพนักงานไม่สำเร็จ กรุณาทำรายการใหม่อีกครั้ง',
        img: close,
        isMultiline: true
    },
    confirm: {
        text: 'ยืนยันการแจ้งเตือน',
    }
}

const createEmpTemplate = (errorMessage) => {
    return {
        success: {
            text: 'สร้างบัญชีพนักงานสำเร็จ',
            img: check,
        },
        failure: {
            text: `${errorMessage} กรุณาทำรายการใหม่อีกครั้ง`,
            img: close,
            isMultiline: true
        },
        confirm: {
            text: 'ยืนยันการสร้างบัญชีพนักงาน',
        }
    }
}

const editEmpTemplate = (errorMessage) => {
    return {
        success: {
            text: 'แก้ไขข้อมูลสำเร็จ',
            img: check,
        },
        failure: {
            text: `${errorMessage} กรุณาทำรายการใหม่อีกครั้ง`,
            img: close,
            isMultiline: true
        },
        confirm: {
            text: 'ยืนยันการแก้ไขพนักงาน',
        }
    }
}

const approveTemplate = {
    success: {
        text: 'ทำรายการสำเร็จ',
        img: check,
    },
    failure: {
        text: 'ทำรายการไม่สำเร็จ กรุณาทำรายการใหม่อีกครั้ง',
        img: close,
        isMultiline: true
    },
    confirm: {
        text: 'ยืนยันการอนุมัติวันลา',
    }
}

const createLeaveTemplate = {
    success: {
        text: 'ส่งใบลาสำเร็จ',
        img: check,
    },
    failure: {
        text: 'ส่งใบลาไม่สำเร็จ กรุณาทำรายการใหม่อีกครั้ง',
        img: close,
        isMultiline: true
    },
    confirm: {
        text: 'ยืนยันการส่งใบลา',
    }
}

const editConstantTemplate = {
    success: {
        text: 'แก้ไขจำนวนวันลาสำเร็จ',
        img: check,
    },
    failure: {
        text: 'แก้ไขจำนวนวันลาไม่สำเร็จ กรุณาทำรายการใหม่อีกครั้ง',
        img: close,
        isMultiline: true
    },
}

const editImageTemplate = {
    success: {
        text: 'แก้ไขรูปภาพสำเร็จ',
        img: check,
    },
    failure: {
        text: 'แก้ไขรูปภาพไม่สำเร็จ กรุณาทำรายการใหม่อีกครั้ง',
        img: close,
        isMultiline: true
    },
    confirm: {
        text: 'ยืนยันการแก้ไขรูปภาพ',
    }
}

const loginTemplate = {
    // success: {
    //     text: 'เข้าสู่ระบบสำเร็จ',
    //     img: check
    // },
    failure: {
        text: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง กรุณาทำรายการใหม่อีกครั้ง',
        img: close,
        isMultiline: true
    },
}

const forgotPasswordTemplate = (errorMessage) => {
    return {
        success: {
            text: 'ส่งอีเมลสำหรับรีเซ็ตรหัสผ่านสำเร็จ กรุณาตรวจสอบอีเมล',
            img: check,
            isMultiline: true
        },
        failure: {
            text: `${errorMessage} กรุณาทำรายการใหม่อีกครั้ง`,
            img: close,
            isMultiline: true
        },
    }
}

const resetPasswordTemplate = (errorMessage) => {
    return {
        success: {
            text: 'รีเซ็ตรหัสผ่านสำเร็จ',
            img: check,
        },
        failure: {
            text: `${errorMessage}`,
            img: close,
            isMultiline: true
        },
    }
}

const resetLeaveTemplate = {
    success: {
        text: 'รีเซ็ตจำนวนวันลาสำเร็จ',
        img: check,
    },
    failure: {
        text: 'การรีเซ็ตจำนวนวันลาไม่สำเร็จ กรุณาทำรายการใหม่อีกครั้ง',
        img: close,
        isMultiline: true
    },
    confirm: {
        text: 'ยืนยันการรีเซ็ตจำนวนวันลา',
    }
}

const serverErrorTemplate = {
    failure: {
        text: 'เกิดข้อผิดพลาด กรุณาทำรายการใหม่อีกครั้ง',
        img: close,
        isMultiline: true
    },
}

export {
    deleteEmpTemplate,
    notificationTemplate,
    createEmpTemplate,
    editEmpTemplate,
    approveTemplate,
    createLeaveTemplate,
    editConstantTemplate,
    editImageTemplate,
    loginTemplate,
    forgotPasswordTemplate,
    resetPasswordTemplate,
    resetLeaveTemplate,
    serverErrorTemplate,
}