import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './LeaveHistory.css'

import { 
    Box, Button, Dialog, DialogTitle, Divider, 
    IconButton, List, ListItem, ListItemText,
    MenuItem, Select, TextField, Typography
} from '@mui/material';
import { ArrowDropDown, FindInPageOutlined, ArticleOutlined } from '@mui/icons-material';

import { DesktopDatePicker } from '@mui/x-date-pickers';

import DataTable from '../../components/DataTable/DataTable'

import { useNavigate } from 'react-router-dom'

import { leaveService } from '../../services/leaveService'

import { leaveTypeList, statusList } from '../../constants/displayText'

import { dateFormat } from '../../utils';

import dayjs from 'dayjs';

const columns = [
    {
        field: 'actions',
        type: 'actions',
        sortable: false,
        flex: 0.6,
        maxWidth: 100,
        renderCell: (params) => <DetailButton leave={params.row} />
    },
    {
        field: 'leave_type',
        headerName: 'ประเภทการลา',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 1,
        minWidth: 150,
        valueGetter: (params) => `${leaveTypeList.find(leaveType => leaveType.value === params.row.leave_type).name}`
    },
    {
        field: 'createdAt',
        headerName: 'วันที่แจ้ง',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 1,
        minWidth: 120,
        valueFormatter: (params) => dateFormat(params.value)
    },
    {
        field: 'status',
        headerName: 'สถานะ',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 1,
        minWidth: 140,
        renderCell: ({ row }) => {
            const currentStatus = statusList.find(status => status.value === row.status);

            return (
                <Typography 
                    className="status" 
                    sx={{ backgroundColor: `var(${currentStatus.color})` }}
                >
                    {currentStatus.name}
                </Typography>
            )
        }
    },
    {
        field: 'reason',
        type: 'actions',
        headerName: 'หมายเหตุ',
        sortable: false,
        flex: 0.6,
        minWidth: 100,
        renderCell: (params) => <NoteButton reason={params.row.reason} />
    },
]

const key = -1;

function LeaveHistory() {

    const [controller, setController] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [leaveList, setLeaveList] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const [openDatePicker, setOpenDatePicker] = useState(false);
    
    const handleOpenDatePicker = () => setOpenDatePicker(true);
    const handleCloseDatePicker = () => setOpenDatePicker(false);

    const [filterType, setFilterType] = useState("");
    const [filterDate, setFilterDate] = useState(null);
    const [filterStatus, setFilterStatus] = useState("");

    const handleChangeType = (e) => setFilterType(e.target.value);
    const handleChangeDate = (newDate) => setFilterDate(newDate);
    const handleChangeStatus = (e) => setFilterStatus(e.target.value);

    const filters = useMemo(() => {
        return {
            filterType, setFilterType, filterDate, setFilterDate, filterStatus, setFilterStatus
        }
    }, [filterType, setFilterType, filterDate, setFilterDate, filterStatus, setFilterStatus]);

    const getFilters = (filters) => {
        const { filterType, filterDate, filterStatus } = filters || {};

        return { 
            filterType: filterType || "", 
            filterDate: filterDate ? dayjs(filterDate).format("YYYY-MM-DD") : null, 
            filterStatus: filterStatus || ""
        };
    }

    const fetchLeaveList = useCallback(async (page, filters, key, controller) => {

        const currentFilters = getFilters(filters);
        console.log('currentFilters => ', currentFilters);
        console.log('key => ', key);

        setCurrentPage(page);
        setLeaveList([]);
        setIsLoading(true);

        try {
            const data = await leaveService.getLeaveByUsername({ page, filters: currentFilters }, controller.signal);
            console.log(data);

            if (data.result) {
                setLeaveList([...data.message]);
                setRowCount(data.rowCount);
            }
            
            setIsLoading(false);

        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {

        const ctrl = new AbortController();

        setController(ctrl);
        fetchLeaveList(0, null, key, ctrl);

    }, [fetchLeaveList]);

    useEffect(() => {

        return () => { controller && controller.abort(); }

    }, [controller]);

    const handleFilterSearch = () => fetchLeaveList(0, filters, key, controller);

    const clearFilter = () => {
        setFilterType("");
        setFilterDate(null);
        setFilterStatus("");
        fetchLeaveList(0, null, key, controller);
    }

    const renderTypeValue = (val) => {
        if (val !== "") {
            const currentLeaveType = leaveTypeList.find(leaveType => leaveType.value === val);
            return currentLeaveType.name;
        }
        
        return "ประเภทการลา : ทั้งหมด";
    }

    const renderStatusValue = (val) => {
        if (val !== "") {
            const currentStatus = statusList.find(status => status.value === val);
            return currentStatus.name;
        }
        
        return "สถานะ : ทั้งหมด";
    }

    return (
        <div className="leave-history">
            <Typography className="header">ประวัติการแจ้งลาทั้งหมด</Typography>

            <Box className="options">
                <Typography className="text">จำนวน {rowCount} รายการ</Typography>
                
                <Item width="15%">
                    <Select
                        className="dropdown"
                        fullWidth
                        displayEmpty
                        value={filterType}
                        renderValue={renderTypeValue}
                        onChange={handleChangeType}
                    >
                        <MenuItem value="">ทั้งหมด</MenuItem>
                        {
                            leaveTypeList.map((leaveType) => (
                                <MenuItem key={leaveType.value} value={leaveType.value}>{leaveType.name}</MenuItem>
                            ))
                        }
                    </Select>
                </Item>

                <Item width="15%">
                    <DesktopDatePicker
                        clearable
                        open={openDatePicker}
                        onOpen={handleOpenDatePicker}
                        onClose={handleCloseDatePicker}
                        inputFormat="DD MMMM BBBB"
                        mask=""
                        inputProps={{
                            placeholder: 'วันที่แจ้ง : ทั้งหมด',
                            readOnly: true
                        }}
                        components={{
                            OpenPickerIcon: ArrowDropDown
                        }}
                        value={filterDate}
                        onChange={handleChangeDate}
                        renderInput={(params) => (
                            <TextField 
                                className="date-picker" 
                                fullWidth 
                                onClick={handleOpenDatePicker}
                                {...params} 
                            />
                        )}
                    />
                </Item>
                    
                <Item width="15%">
                    <Select
                        className="dropdown"
                        fullWidth
                        displayEmpty
                        value={filterStatus}
                        renderValue={renderStatusValue}
                        onChange={handleChangeStatus}
                    >
                        <MenuItem value="">ทั้งหมด</MenuItem>
                        {
                            statusList.map((status) => (
                                <MenuItem key={status.value} value={status.value}>{status.name}</MenuItem>
                            ))
                        }
                    </Select>
                </Item>

                <Item width="20%" className="button">
                    <Button 
                        fullWidth 
                        variant="contained" 
                        className="filter-button"
                        onClick={handleFilterSearch}
                    >
                        ค้นหา
                    </Button>
                    <Button 
                        fullWidth 
                        variant="contained" 
                        className="filter-button"
                        onClick={clearFilter}
                    >
                        ล้าง
                    </Button>
                </Item>

            </Box>

            <DataTable 
                currentPage={currentPage}
                rows={leaveList} 
                rowCount={rowCount}
                columns={columns} 
                isLoading={isLoading} 
                fetchFunction={fetchLeaveList}
                filters={filters}
                keyCode={key}
            />
            
        </div>
    )
}

export default LeaveHistory

function Item({ children, width, className }) {
    return (
        <Box className={className} sx={{ width }}>
            {children}
        </Box>
    )
}

function DetailButton({ leave }) {

    const navigate = useNavigate();

    const handleDetailClick = () => navigate(`leave-detail/${leave.id}`);

    return (
        <IconButton onClick={handleDetailClick}>
            <FindInPageOutlined className="detail-button" />
        </IconButton>
    )
}

function NoteButton({ reason }) {

    const [openDialog, setOpenDialog] = useState(false);
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    const handleClick = () => {
        handleOpenDialog();
    }

    if (!reason) {
        return <>-</>
    }

    return (
        <>
            <IconButton onClick={handleClick}>
                <ArticleOutlined className="note-button" />
            </IconButton>
            
            <Dialog fullWidth onClose={handleCloseDialog} open={openDialog}>
                <DialogTitle>
                    <Typography sx={{ fontSize: "20px", fontWeight: "var(--bold-weight)" }}>หมายเหตุ</Typography>
                </DialogTitle>
                <Divider variant="middle" />
                <List sx={{ pt: 0 }}>
                    <ListItem>
                        <ListItemText sx={{ pl: 2, pr: 2 }} primary={reason} />
                    </ListItem>
                </List>
            </Dialog>
        </>
    )
}