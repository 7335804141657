import { 
    AssignmentOutlined, CalendarMonthOutlined, History, ImageOutlined, 
    NoteAltOutlined, NotificationsOutlined, PersonOutline, RuleOutlined, Refresh
} from '@mui/icons-material';

export const menuList = [
    {
        text: 'รายการวันลา',
        icon: <AssignmentOutlined />,
        path: '/leave-list',
        role: ['MGR']
    },
    {
        text: 'สร้างบัญชีพนักงาน',
        icon: <PersonOutline />,
        path: '/create-employee',
        role: ['MGR']
    },
    {
        text: 'จัดการข้อมูลพนักงาน',
        icon: <NoteAltOutlined />,
        path: '/employee-management',
        role: ['MGR']
    },
    {
        text: 'แจ้งเตือนพนักงาน',
        icon: <NotificationsOutlined />,
        path: '/notification',
        role: ['MGR']
    },
    {
        text: 'แจ้งลา',
        icon: <AssignmentOutlined />,
        path: '/create-leave',
        role: ['EMP']
    },
    {
        text: 'ประวัติการแจ้งลา',
        icon: <History />,
        path: '/leave-history',
        role: ['EMP']
    },
    {
        text: 'การแจ้งเตือน',
        icon: <NotificationsOutlined />,
        path: '/notification',
        role: ['EMP']
    },
    {
        text: 'ปฏิทินวันหยุด',
        icon: <CalendarMonthOutlined />,
        path: '/calendar',
        role: ['EMP']
    },
    {
        text: 'กฎการลา',
        icon: <RuleOutlined />,
        path: '/rule',
        role: ['EMP']
    },
]

export const settingList = [
    {
        text: 'ตั้งค่าจำนวนวันลา',
        icon: <NoteAltOutlined />,
        path: '/setting/leave-amount',
        role: ['MGR']
    },
    {
        text: 'ตั้งค่ารูปภาพ',
        icon: <ImageOutlined />,
        path: '/setting/images',
        role: ['MGR']
    },
    {
        text: 'รีเซ็ตจำนวนวันลา',
        icon: <Refresh />,
        path: '/setting/reset-leve',
        role: ['MGR']
    },
]