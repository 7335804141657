import { useAxios } from "../guard/axiosConfig";

export const imageService = {
    uploadImage,
    getImageByType,
}

async function uploadImage(imageData) {
    const formData = new FormData();

    formData.append('data', JSON.stringify({
        image_type: imageData.image_type
    }));

    formData.append('image', imageData.image);

    const { data } = await useAxios.post('/image/uploadImage', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });

    return data;
}

async function getImageByType(image_types) {
    const { data } = await useAxios.post('/image/getImageByType', { image_types });

    return data;
}