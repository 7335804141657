import React, { useEffect, useMemo, useState } from "react";
import './ImageUploadForm.css'

import useModal from "../../../hooks/useModal";

import { Button, Divider, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';

import CustomModal from '../../../components/CustomModal/CustomModal'
import ImagePreview from '../../../components/ImagePreview/ImagePreview';

import { editImageTemplate, serverErrorTemplate } from '../../../constants/modalConstant'
import { imageTypeList } from "../../../constants/displayText";

import { imageService } from "../../../services/imageService";

function ImageUploadForm() {

    const {
        openModal,
        handleOpen,
        handleClose,
        contentType,
        successModalContent,
        failModalContent,
        confirmModalContent,
        modalType,
        confirmModalType,
        responseModalType,
        loadingModalType,
        isSuccess,
        responseSuccess,
        responseFail,
    } = useModal();

    const [template, setTemplate] = useState(editImageTemplate);

    const [images, setImages] = useState({
        calendar_img: null,
        rule_img: null,
    });

    const [image, setImage] = useState(null);
    const [image_type, setImageType] = useState("calendar_img");

    const handleDeleteClick = () => setImage(null);

    useEffect(() => {

        let isMounted = true;

        const fetchImage = async () => {

            if (isMounted) {
                loadingModalType();
                handleOpen();
            }

            try {
                const data = await imageService.getImageByType(["calendar_img", "rule_img"]);
                console.log(data);

                if (data.result) {
                    data.message.map(image => isMounted && setImages(prev => {
                        return { ...prev, [image.image_type]: image }
                    }))
                }

                isMounted && handleClose();

            } catch (error) {
                console.log(error);
                isMounted && handleClose();
            }
        }

        fetchImage();

        return () => { isMounted = false; }
    }, []);

    const handleFileChange = ({ target }) => {
        const image = target.files[0];

        setImage({
            data: image,
            originalname: image.name,
            imagePreview: URL.createObjectURL(image),
        });

        target.value = null;
    }

    const handleChange = (e) => setImageType(e.target.value);

    const handleUpload = () => {
        setTemplate(editImageTemplate);
        confirmModalType();
        confirmModalContent();
        handleOpen();
    }

    const handleAcceptClick = async () => {

        const imageData = {
            image_type, 
            image: image.data
        }

        console.log(imageData);

        handleClose();
        loadingModalType();
        handleOpen();

        try {
            const data = await imageService.uploadImage(imageData);
            console.log(data);

            handleClose();
            responseModalType();

            if (data.result) {
                successModalContent();
                responseSuccess();
            } else {
                failModalContent();
                responseFail();
            }

            handleOpen();

        } catch (error) {
            console.log(error);
            handleClose();
            responseModalType();
            setTemplate(serverErrorTemplate);
            failModalContent();
            responseFail();
            handleOpen();
        }
    }

    const updateImageAndClearForm = () => {
        setImages((prev) => {
            return {
                ...prev,
                [image_type]: image,
            }
        })
        setImage(null);
    }

    const renderImage = useMemo(() => {

        if (!images.calendar_img && !images.rule_img) {
            return <Typography marginY={5}>ไม่พบรูปภาพ</Typography>
        }

        return Object.keys(images).map(type => {
            const currentImage = images[type];

            if (!currentImage) return null;

            const imageType = imageTypeList.find(current => current.type === type);

            return (
                <Grid 
                    key={type} 
                    display="flex" 
                    flexDirection="column"
                    item xs={12} sm={5} 
                >
                    {
                        // !currentImage ? (
                        //     <Typography sx={{ width: '100%', height: '100%', textAlign: 'center' }}>ไม่มีรูปภาพ</Typography>
                        // ) : (
                        //     <ImagePreview image={currentImage} />
                        // )
                    }
                    <ImagePreview image={currentImage} />
                    <Typography align="center" fontSize={13} mt={1}>{imageType.name}</Typography>
                </Grid>
            )
        })
    }, [images])

    return (
        <>
            <div className="image-upload">
                <Typography className="header">ตั้งค่ารูปภาพ</Typography>
                <Divider sx={{ mt: '20px', mb: '36px' }} />

                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    rowGap={4}
                >
                    <GridItem>
                        <Typography className="label">รูปภาพปัจจุบัน</Typography>
                        <Grid 
                            container
                            justifyContent="center"
                            columnGap={1}
                            rowGap={3}
                            sx={{ mt: "4px" }}
                            className="file-container"
                        >
                            {renderImage}
                        </Grid>
                    </GridItem>

                    <GridItem>
                        <Typography className="label">ประเภทรูปภาพที่ต้องการอัปโหลด</Typography>
                        <RadioGroup
                            value={image_type}
                            onChange={handleChange}
                            sx={{ 
                                flexDirection: { xs: "column", sm: "row" }, 
                                justifyContent: { sm: "space-around" },
                            }}
                        >
                            {
                                imageTypeList.map(imageType => (
                                    <FormControlLabel 
                                        key={imageType.type}
                                        value={imageType.type} 
                                        control={<Radio />} 
                                        label={imageType.name} 
                                    />
                                ))
                            }
                        </RadioGroup>
                    </GridItem>

                    <GridItem>
                        <Typography className="label">อัปโหลดรูปภาพ</Typography>
                        <div className="file-container">
                            <Button variant="contained" component="label" className="upload">
                                อัปโหลด
                                <input
                                    hidden
                                    className="input-file"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileChange} 
                                />
                            </Button>
                            { 
                                image && (
                                    <Grid 
                                        container
                                        justifyContent="center"
                                        sx={{ mt: "4px" }}
                                    >
                                        <Grid item xs={6}>
                                            <ImagePreview deleteable image={image} onDelete={handleDeleteClick} />
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </div>
                    </GridItem>
                </Grid>

                <div className="button">
                    <Button 
                        variant="contained" 
                        className="submit-btn"
                        disabled={!image} 
                        onClick={handleUpload}
                    >
                        บันทึก
                    </Button>
                </div>
            </div>

            <CustomModal 
                template={template}
                onAccept={handleAcceptClick}
                onDecline={handleClose}
                taskAfterSuccess={updateImageAndClearForm}
                taskAfterFailure={() => console.log('after fail')}
                useModal={{
                    openModal,
                    handleClose,
                    isSuccess,
                    contentType,
                    modalType
                }} 
            />
        </>
    )
}

export default ImageUploadForm

function GridItem({ children }) {
    return (
        <Grid item xs={9}>
            {children}
        </Grid>
    )
}