import React, { useEffect, useState } from 'react'
import './NotificationList.css'

import { useNavigate } from 'react-router-dom'

import { Button, Divider, Typography } from '@mui/material'

import { notificationService } from '../../services/notificationService'

import CustomModal from '../../components/CustomModal/CustomModal'
import Notification from '../../components/Notification/Notification'

import useModal from '../../hooks/useModal'

import { serverErrorTemplate } from '../../constants/modalConstant';

import useAuth from '../../hooks/useAuth'

function NotificationList() {

    const { auth } = useAuth();
    const navigate = useNavigate();

    const {
        openModal,
        handleOpen,
        handleClose,
        contentType,
        failModalContent,
        modalType,
        responseModalType,
        loadingModalType,
        isSuccess,
        responseFail,
    } = useModal();

    const [template, setTemplate] = useState(serverErrorTemplate);
    
    const [notificationList, setNotificationList] = useState([]);

    const goToNotificationForm = () => navigate('create');

    useEffect(() => {
        
        let isMounted = true;

        const fetchNotificationList = async () => {
            
            if (isMounted) {
                loadingModalType();
                handleOpen();
            }

            try {
                const data = await notificationService.getNotificationByUser();
                console.log(data);

                if (data.result) {
                    if (isMounted) setNotificationList([...data.message]);
                }
                
                isMounted && handleClose();

            } catch (error) {
                console.log(error);
                if (isMounted) {
                    handleClose();
                    responseModalType();
                    setTemplate(serverErrorTemplate);
                    failModalContent();
                    responseFail();
                    handleOpen();
                }
            }
        }

        fetchNotificationList();

        return () => { isMounted = false }

    }, [])

    return (
        <div className="notification-list">
            <div className="notification-list-header">
                <Typography className="header">การแจ้งเตือน</Typography>
                {
                    auth.role === "MGR" && (
                        <Button 
                            variant="contained" 
                            className="create"
                            onClick={goToNotificationForm}
                        >
                            สร้าง
                        </Button>
                    )
                }
            </div>
            <Divider sx={{ mt: '20px', mb: '36px' }} />

            <div className="list-container">
                {
                    notificationList.length === 0 ? (
                        <Typography className="not-found">ไม่พบการแจ้งเตือน</Typography>
                    ) : (
                        notificationList.map(noti => (
                            <Notification key={noti.id} notification={noti} />
                        ))
                    )
                }
            </div>

            <CustomModal 
                template={template}
                taskAfterFailure={() => console.log('after fail')}
                useModal={{
                    openModal,
                    handleClose,
                    isSuccess,
                    contentType,
                    modalType
                }} 
            />
        </div>
    )
}

export default NotificationList