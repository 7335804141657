import { useAxios } from "../guard/axiosConfig";

export const leaveConstantService = {
    getAllConstant,
    updateConstant,
    getConstantByUser
}

async function getAllConstant() {
    const { data } = await useAxios.get('/leaveConstant/getAllConstant');

    return data;
}

async function updateConstant(leaveConstant) {
    const { data } = await useAxios.post('/leaveConstant/updateConstant', { ...leaveConstant });

    return data;
}

async function getConstantByUser() {
    const { data } = await useAxios.get('/leaveConstant/getConstantByUser');

    return data;
}