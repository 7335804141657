import React, { useEffect, useState } from 'react'
import './LeaveAmount.css'

import { useNavigate } from 'react-router-dom'

import { 
    Button, Divider, Grid, Paper, Table, TableBody, 
    TableCell, TableContainer, TableHead, TableRow, Typography 
} from '@mui/material';

import { HOUR_PER_DAY, leaveTypeList } from '../../constants/displayText'

import { leaveConstantService } from '../../services/leaveConstantService';

function LeaveAmount() {

    const navigate = useNavigate();

    // TODO: add loading
    const [isLoading, setIsLoading] = useState(false);
    const [leaveAmount, setLeaveAmount] = useState({
        sick_leave_hour_amount: 0,
        sick_leave_hour_used: 0,
        personal_leave_hour_amount: 0,
        personal_leave_hour_used: 0,
        vacation_leave_hour_amount: 0,
        vacation_leave_hour_used: 0,
    });

    const goToCreateLeave = () => navigate('create');

    useEffect(() => {

        let isMounted = true;

        const fetchConstant = async () => {

            isMounted && setIsLoading(true);
            
            try {
                const data = await leaveConstantService.getConstantByUser();
                console.log(data);

                if (data.result) {
                    if (isMounted) {
                        setLeaveAmount({ ...data.message });
                    }
                }

                isMounted && setIsLoading(false);

            } catch (error) {
                console.log(error);
                isMounted && setIsLoading(false);
            }
        }

        fetchConstant();

        return () => { isMounted = false }

    }, []);

    const getLeaveAmountForTable = () => {
        const obj = {};

        Object.keys(leaveAmount).map(type => {
            const mainType = getMainType(type);
            const subType = getSubType(type);

            obj[mainType] = {
                ...obj[mainType],
                [subType]: leaveAmount[type]
            }

            return null;
        });

        return obj;
    }

    const getMainType = (type) => {

        if (type.includes('sick')) return 'sick';

        if (type.includes('personal')) return 'personal';
        
        if (type.includes('vacation')) return 'vacation';

    }

    const getSubType = (type) => {

        if (type.includes('amount')) return 'amount';

        if (type.includes('used')) return 'used';

    }
    
    const leaveAmountForTable = getLeaveAmountForTable();

    const getRemain = (type, used) => {
        const amount = leaveAmountForTable[type].amount;
        const remain = (amount - used) / HOUR_PER_DAY;

        if (remain % 1 === 0) return `${remain} วัน`;

        const days = remain < 0 ? Math.ceil(remain) : Math.floor(remain);
        const hours = (remain % 1) * HOUR_PER_DAY;
        
        let result = "";
        result += days === 0 ? "" : `${days} วัน`;
        result += hours === 0 ? "" : ` ${hours} ชั่วโมง`;

        return result;
    }

    return (
        <div className="leave-amount">
            <div className="leave-amount-header">
                <Typography className="header">จำนวนวันลา</Typography>
                <Button 
                    variant="contained" 
                    className="create"
                    onClick={goToCreateLeave}
                >
                    สร้างใบลา
                </Button>
            </div>
            <Divider sx={{ mt: '20px', mb: '36px' }} />

            <Grid
                container
                justifyContent="center"
            >
                <Grid item xs={12} sm={10}>
                    <TableContainer component={Paper} className="table">
                        <Table sx={{ minWidth: { xs: 450, sm: 0} }}>
                            <TableHead className="table-head">
                                <TableRow>
                                    <TableCell align="center">ประเภทวันลา</TableCell>
                                    <TableCell align="center">จำนวนที่ใช้ได้ทั้งหมด</TableCell>
                                    <TableCell align="center">คงเหลือ</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="table-body">
                                {
                                    Object.keys(leaveAmountForTable).map(type => (
                                        <TableRow
                                            key={type}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" align="center">
                                                {leaveTypeList.find(leaveType => leaveType.value === type.toUpperCase()).name}
                                            </TableCell>
                                            <TableCell align="center">{leaveAmountForTable[type].amount / HOUR_PER_DAY} วัน</TableCell>
                                            <TableCell align="center">{getRemain(type, leaveAmountForTable[type].used)}</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default LeaveAmount