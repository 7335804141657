import React, { useState } from 'react'
import './InputModal.css'

import { Button, Divider, TextField, Typography } from '@mui/material'

function InputModal({ onAccept, onDecline }) {

    const [reason, setReason] = useState("");

    const handleChange = (e) => setReason(e.target.value);

    const handleAcceptClick = () => onAccept("REFUSE", reason);
    const handleDeclineClick = () => onDecline();

    return (
        <div className="input-modal">
            <div className="title-container">
                <Typography className="title">หมายเหตุ:</Typography>
                <Divider sx={{ mt: 1 }} />
            </div>

            <TextField 
                className="input-form"
                fullWidth
                multiline
                minRows={4}
                maxRows={4}
                value={reason}
                onChange={handleChange}
            />
            
            <div className="button">
                <Button 
                    variant="contained" 
                    className="accept" 
                    onClick={handleAcceptClick}
                >
                    ยืนยัน
                </Button>
                <Button 
                    variant="contained" 
                    className="decline" 
                    onClick={handleDeclineClick}
                >
                    ยกเลิก
                </Button>
            </div>
        </div>
    )
}

export default InputModal