import React, { useEffect, useState } from 'react'
import './ResetLeave.css'

import useModal from '../../../hooks/useModal'

import { Button, Divider, Typography } from '@mui/material'

import CustomModal from '../../../components/CustomModal/CustomModal'
import ReportList from '../../../components/ReportList/ReportList';

import { resetLeaveTemplate, serverErrorTemplate } from '../../../constants/modalConstant'

import { userService } from '../../../services/userService';
import { reportService } from '../../../services/reportService';

function ResetLeave() {

    const {
        openModal,
        handleOpen,
        handleClose,
        contentType,
        successModalContent,
        failModalContent,
        confirmModalContent,
        modalType,
        confirmModalType,
        responseModalType,
        loadingModalType,
        isSuccess,
        responseSuccess,
        responseFail,
    } = useModal();
    
    const [template, setTemplate] = useState(resetLeaveTemplate);
    const [reportList, setReportList] = useState([]);

    useEffect(() => {

        let isMounted = true;

        const getReports = async () => {
            try {
                const data = await reportService.getReports();
                console.log(data);
                if (data.result) {
                    isMounted && setReportList([...data.message]);
                }
            } catch (error) {
                console.log(error);
            }
        }

        getReports();

        return () => { isMounted = false }

    }, []);

    const handleResetLeave = () => {
        console.log('reset');

        setTemplate(resetLeaveTemplate);
        confirmModalType();
        confirmModalContent();
        handleOpen();
    }

    const handleAcceptClick = async () => {

        handleClose();
        loadingModalType();
        handleOpen();

        try {
            const data = await userService.resetLeaveUsed();
            console.log(data);

            handleClose();
            responseModalType();

            if (data.result) {
                setReportList([data.message, ...reportList])
                successModalContent();
                responseSuccess();
            } else {
                failModalContent();
                responseFail();
            }

            handleOpen();

        } catch (error) {
            console.log(error);
            handleClose();
            responseModalType();
            setTemplate(serverErrorTemplate);
            failModalContent();
            responseFail();
            handleOpen();
        }
    }

    return (
        <>
            <div className="reset-leave">
                <Typography className="header">รีเซ็ตจำนวนวันลา</Typography>
                <Divider sx={{ mt: '20px', mb: '36px' }} />

                <div className="button">
                    <Button 
                        color="error"
                        size="large" 
                        variant="contained" 
                        onClick={handleResetLeave}
                    >
                        รีเซ็ตจำนวนวันลา
                    </Button>
                </div>

                <ReportList list={reportList} />
            </div>
            
            <CustomModal 
                template={template}
                onAccept={handleAcceptClick}
                onDecline={handleClose}
                taskAfterSuccess={() => console.log('after success')}
                taskAfterFailure={() => console.log('after fail')}
                useModal={{
                    openModal,
                    handleClose,
                    isSuccess,
                    contentType,
                    modalType
                }} 
            />
        </>
    )
}

export default ResetLeave
