import React, { useEffect, useState } from 'react'
import './NotificationForm.css'

import useModal from '../../hooks/useModal'
import useSocket from '../../hooks/useSocket'

import { 
    Box, Button, Checkbox, Chip, Divider, Grid, 
    ListItemText, MenuItem, Select, TextField, Typography 
} from '@mui/material'

import CustomModal from '../../components/CustomModal/CustomModal'

import { notificationTemplate, serverErrorTemplate } from '../../constants/modalConstant'

import { userService } from '../../services/userService'
import { notificationService } from '../../services/notificationService'

import { useNavigate } from 'react-router-dom'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function NotificationForm() {
    
    const { createNotification } = useSocket();
    const navigate = useNavigate();

    const [template, setTemplate] = useState(notificationTemplate);
    const [empList, setEmpList] = useState([]);
    const [notification, setNotification] = useState({
        emps: [],
        text: '',
    });
    
    const {
        openModal,
        handleOpen,
        handleClose,
        contentType,
        confirmModalContent,
        successModalContent,
        failModalContent,
        modalType,
        confirmModalType,
        responseModalType,
        loadingModalType,
        isSuccess,
        responseSuccess,
        responseFail,
    } = useModal();

    useEffect(() => {

        let isMounted = true;

        const fetchEmpList = async () => {
            
            if (isMounted) {
                loadingModalType();
                handleOpen();
            }

            try {
                const data = await userService.getAllEmployee();
                console.log(data);

                if (data.result) {
                    if (isMounted) setEmpList([...data.message]);
                }
                
                isMounted && handleClose();

            } catch (error) {
                console.log(error);
                if (isMounted) {
                    handleClose();
                    responseModalType();
                    setTemplate(serverErrorTemplate);
                    failModalContent();
                    responseFail();
                    handleOpen();
                }
            }
        }

        fetchEmpList();

        return () => { isMounted = false }

    }, [])

    useEffect(() => {
        console.log(notification.emps);
    }, [notification.emps])

    const goToNotificationList = () => navigate('/notification');

    const handleSubmit = (e) => {
        e.preventDefault();

        console.log(notification);

        setTemplate(notificationTemplate);
        confirmModalType();
        confirmModalContent();
        handleOpen();
    }

    const handleAcceptClick = async () => {
        handleClose();
        loadingModalType();
        handleOpen();

        try {
            const data = await notificationService.createNotification(notification);
            console.log(data);

            handleClose();
            responseModalType();

            if (data.result) {
                createNotification(data.message);
                successModalContent();
                responseSuccess();
            } else {
                failModalContent();
                responseFail();
            }

            handleOpen();

        } catch (error) {
            console.log(error);
            handleClose();
            setTemplate(serverErrorTemplate);
            failModalContent();
            responseFail();
            handleOpen();
        }
    }

    const handleTextChange = (e) => {
        setNotification({
            ...notification,
            text: e.target.value
        });
    }

    const handleSelectChange = (e) => {
        const { value } = e.target;

        // ถ้ากด 'เลือกทั้งหมด' => -1
        if (value.includes(-1)) {
            
            // เช็คว่า รายชื่อที่โดนเลือกไปแล้ว มีขนาดมากกว่า รายชื่อทั้งหมด ไหม?
            // ถ้ามากกว่า หมายความว่าเป็นการ unchecked 'เลือกทั้งหมด' ออก
            if (value.length > empList.length) {

                // set ให้ emps ว่าง
                setNotification({
                    ...notification,
                    emps: []
                });

                return;
            }

            // ถ้าไม่เข้าเงื่อนไขด้านบน หมายความว่า
            // เป็นการ checked 'เลือกทั้งหมด'
            // ใส่รายชื่อทั้งหมด เข้า emps
            setNotification({
                ...notification,
                emps: empList.map(emp => emp.id)
            });
            
            return;
        }

        // ถ้าไม่เข้าเงื่อนไขด้านบน หมายความว่า
        // เป็นการ checked รายชื่อแต่ละคนเข้า
        // ใส่รายชื่อที่ถูกเลือกไปแล้ว เข้า emps
        setNotification({
            ...notification,
            emps: [...value],
        });
    };

    const renderValue = (selected) => {
        if (selected.length === 0) {
            return <span>กรุณาเลือกพนักงาน</span>;
        }

        if (selected.length === empList.length) {
            return <span>เลือกทั้งหมด</span>;
        }

        // TODO: เลือกแบบว่าจะเอาต่อ string หรือ chip
        return selected.map(item => {
            const emp = empList.find(emp => emp.id === item);
            return `${emp.first_name} ${emp.last_name}`;
        }).join(', ');
        
        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {
                    selected.map(item => {
                        const emp = empList.find(emp => emp.id === item);
                        return `${emp.first_name} ${emp.last_name}`;
                    }).map((value) => (
                        <Chip key={value} label={value} />
                    ))
                }
            </Box>
        );
    }

    return (
        <div className="notification-form">
            <Typography className="header">แจ้งเตือนพนักงาน</Typography>
            <Divider sx={{ mt: '20px', mb: '36px' }} />

            <form onSubmit={handleSubmit}>
                <Grid container justifyContent="center" rowGap={4} columns={{ xs: 15 }}>

                    <Grid container item alignItems="center" xs={10}>
                        <Grid item xs={12} sm={2}>
                            <Typography className="input-label">ส่งถึง:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Select
                                fullWidth
                                multiple
                                required
                                displayEmpty
                                className="input-form"
                                value={notification.emps}
                                onChange={handleSelectChange}
                                renderValue={renderValue}
                                MenuProps={MenuProps}
                            >
                                {/* <MenuItem disabled value=""> */}
                                    {/* <ListItemText primary={"กรุณาเลือกพนักงาน"} /> */}
                                {/* </MenuItem> */}
                                <MenuItem value={-1}>
                                    <Checkbox checked={notification.emps.length === empList.length} />
                                    <ListItemText primary={"เลือกทั้งหมด"} sx={{ color: 'var(--main-text-color)' }} />
                                </MenuItem>
                                <Divider variant='middle' />
                                {
                                    empList.map((emp) => (
                                        <MenuItem key={emp.id} value={emp.id}>
                                            <Checkbox checked={notification.emps.indexOf(emp.id) > -1} />
                                            <ListItemText 
                                                primary={`${emp.first_name} ${emp.last_name}`} 
                                                sx={{ color: 'var(--main-text-color)' }} 
                                            />
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                    </Grid>
                    
                    <Grid container item justifyContent="flex-start" xs={10}>
                        <Typography className="input-label">ข้อความ:</Typography>
                        <TextField
                            fullWidth 
                            required
                            multiline
                            minRows={7}
                            maxRows={7}
                            className="input-form"
                            placeholder="กรุณาระบุข้อความ"
                            value={notification.text}
                            onChange={handleTextChange} />
                    </Grid>
                </Grid>


                <div className="button">
                    <Button 
                        variant="contained" 
                        type="submit"
                    >
                        บันทึก
                    </Button>
                </div>
            </form>

            <CustomModal 
                template={template}
                onAccept={handleAcceptClick}
                onDecline={handleClose}
                taskAfterSuccess={goToNotificationList}
                taskAfterFailure={() => console.log('noti fail')}
                useModal={{
                    openModal,
                    handleClose,
                    isSuccess,
                    contentType,
                    modalType
                }} 
            />
        </div>
    )
}

export default NotificationForm