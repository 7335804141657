import React, { useMemo } from 'react'
import './Sidebar.css'
import logo from '../../assets/images/FUTUREWALL-04.png'

import { Divider, Icon, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material';

import { useLocation, useNavigate } from 'react-router-dom';

import { menuList, settingList } from '../../constants/menu'

import useAuth from '../../hooks/useAuth';

function Sidebar({ mobileOpen, handleDrawerToggle }) {

    const { auth } = useAuth();

    const { pathname } = useLocation();
    const navigate = useNavigate();

    const menuContent = useMemo(() => {

        if (auth.role === "EMP") return menuList;

        return pathname.includes("setting") ? settingList : menuList

    }, [pathname, auth.role]);

    const handleSidebarClick = (path) => {
        navigate(`${path}`);
        
        if (mobileOpen) {
            handleDrawerToggle();
        }
    }

    const handleMainLogoClick = () => {
        navigate("/");

        if (mobileOpen) {
            handleDrawerToggle();
        }
    }
    
    return (
        <div className="sidebar">
            <Toolbar className="main-logo" onClick={handleMainLogoClick}>
                <img src={logo} width="50" alt=""/>
                <Typography className="text">FWEHOUSE</Typography>
            </Toolbar>

            <Divider variant='middle' sx={{ backgroundColor: "#F2F2F2"}}/>

            <List className="sidebar-menu">
                {
                    menuContent.filter(menu => menu.role.includes(auth.role)).map((menu) => (
                        <ListItem 
                            button
                            className={`menu ${pathname.includes(menu.path) ? 'active' : null}`}
                            key={menu.text}
                            onClick={() => handleSidebarClick(menu.path)}
                        >
                            <ListItemIcon>
                                <Icon sx={{ color: "#E3E0E0" }}>{menu.icon}</Icon>
                            </ListItemIcon>
                            <ListItemText primary={menu.text} className="text" />
                            
                            { mobileOpen === undefined && pathname.includes(menu.path) && <span className="triangle"></span> }
                        </ListItem>
                    ))
                }
            </List>
        </div>
    )
}

export default Sidebar