const HOUR_PER_DAY = 8;

const days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
    11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    31
];

const statusList = [
    {
        name: 'รอดำเนินการ',
        value: 'PENDING',
        color: '--yellow-color'
    },
    {
        name: 'อนุมัติ',
        value: 'APPROVE',
        color: '--green-color'
    },
    {
        name: 'ไม่อนุมัติ',
        value: 'REFUSE',
        color: '--red-color'
    },
]

const leaveTypeList = [
    {
        name: 'ลาป่วย',
        value: 'SICK',
    },
    {
        name: 'ลาพักร้อน',
        value: 'VACATION',
    },
    {
        name: 'ลากิจ',
        value: 'PERSONAL',
    },
]

const roles = [
    {
        role: 'EMP',
        name: 'พนักงาน'
    },
    {
        role: 'MGR',
        name: 'ผู้จัดการ'
    },
]

const imageTypeList = [
    {
        type: 'calendar_img',
        name: 'รูปปฏิทินวันหยุด',
    },
    {
        type: 'rule_img',
        name: 'รูปกฎการลา',
    }
]

const leaveTypeTooltip = {
    personal: {
        text: 'หมายถึง การลาเพื่อไปทำกิจธุระจำเป็น ที่คนอื่นไม่สามารถไปทำแทนได้',
        example: [
            'ลาไปติดต่อหน่วยงาน',
            'ลาไปเป็นพยานตามหมายเรียกของศาล',
            'ลาไปฉีดวัคซีนโควิดตามกำหนดที่หน่วยงานนัดหมาย',
            'ลาไปงานศพของคนในครอบครัว (พ่อ แม่ พี่น้อง สามี ภรรยา ลูก)',
        ],
    },
    sick: {
        text: 'การลาป่วย 2 วันขึ้นไป กรุณาแนบใบรับรองแพทย์',
    },
}

export {
    HOUR_PER_DAY,
    days,
    statusList,
    leaveTypeList,
    roles,
    imageTypeList,
    leaveTypeTooltip,
}