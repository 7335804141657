import { useAxios } from "../guard/axiosConfig";

export const userService = {
    getAllUser,
    getAllEmployee,
    getUserById,
    getUserByListOfId,
    createUser,
    updateUser,
    deleteUser,
    resetLeaveUsed,
}

async function getAllUser(obj, signal) {
    const { data } = await useAxios.post('/user/getAllUser', obj, { signal });

    return data;
}

async function getAllEmployee() {
    const { data } = await useAxios.get('/user/employee');

    return data;
}

async function getUserById(id) {
    const { data } = await useAxios.post('/user/getUserById', { id });

    return data;
}

async function getUserByListOfId(list) {
    const { data } = await useAxios.post('/user/getUserByListOfId', { list });

    return data;
}

async function createUser(emp) {
    const { data } = await useAxios.post('/user/createUser', { ...emp });

    return data;
}

async function updateUser(emp) {
    const { data } = await useAxios.post('/user/update', { ...emp });

    return data;
}

async function deleteUser(id) {
    const { data } = await useAxios.post('/user/delete', { id });

    return data;
}

async function resetLeaveUsed() {
    const { data } = await useAxios.get('/user/resetLeaveUsed');

    return data;
}