import axios from "axios"

const baseURL = "https://api.futurewallhouse.in.th";

const instance = axios.create({
    baseURL: `${baseURL}/api`,
    withCredentials: true,
    headers: { "Content-Type": "application/json" }
});

instance.interceptors.request.use( async (req) => {

    if (!req.url.includes('/auth')) {

        const { data } = await axios.get(
            `${baseURL}/api/auth/refresh`,
            { withCredentials: true, signal: req.signal }
        );

        console.log(data);

        if (data.message === "TokenInvalid") {
            localStorage.clear("user");
            window.location.reload();
        }

    }

    return req;
});

export const useAxios = instance;