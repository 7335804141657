import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './LeaveList.css'

import { 
    Box, Button, Dialog, DialogTitle, Divider, 
    FormControlLabel, IconButton, List, ListItem, ListItemText, 
    MenuItem, Select, Switch, TextField, Typography,
} from '@mui/material';
import { ArrowDropDown, ArticleOutlined, FindInPageOutlined } from '@mui/icons-material';

import { DesktopDatePicker } from '@mui/x-date-pickers';

import { useNavigate } from 'react-router-dom'

import DataTable from '../../components/DataTable/DataTable';

import { leaveService } from '../../services/leaveService'

import { days, statusList } from '../../constants/displayText'

import { dateFormat } from '../../utils';

import dayjs from 'dayjs';

const columns = [
    {
        field: 'actions',
        type: 'actions',
        sortable: false,
        flex: 0.6,
        maxWidth: 100,
        renderCell: (params) => <DetailButton leaveId={params.row.id} />
    },
    {
        field: 'empName',
        headerName: 'พนักงาน',
        headerAlign: 'center',
        sortable: false,
        flex: 1,
        minWidth: 150,
        valueGetter: (params) => `${params.row.User.first_name} ${params.row.User.last_name}`
    },
    {
        field: 'createdAt',
        headerName: 'วันที่แจ้ง',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 1,
        minWidth: 120,
        valueFormatter: (params) => dateFormat(params.value)
    },
    {
        field: 'status',
        headerName: 'สถานะ',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 1,
        minWidth: 140,
        renderCell: ({ row }) => {
            const currentStatus = statusList.find(status => status.value === row.status);

            return (
                <Typography 
                    className="status" 
                    sx={{ backgroundColor: `var(${currentStatus.color})` }}
                >
                    {currentStatus.name}
                </Typography>
            )
        }
    },
    {
        field: 'reason',
        type: 'actions',
        headerName: 'หมายเหตุ',
        sortable: false,
        flex: 0.6,
        minWidth: 100,
        renderCell: (params) => <NoteButton reason={params.row.reason} />
    },
]

function LeaveList() {

    const [controller, setController] = useState(null);

    const [checked, setChecked] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [leaveList, setLeaveList] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const [filterEmp, setFilterEmp] = useState("");
    const [filterDate, setFilterDate] = useState(null);
    const [filterStatus, setFilterStatus] = useState("");
    const [filterDay, setFilterDay] = useState("");
    const [filterMonth, setFilterMonth] = useState(null);
    const [filterYear, setFilterYear] = useState(null);

    const generalFilter = useMemo(() => {
        return {
            filterEmp, setFilterEmp, 
            filterDate, setFilterDate, 
            filterStatus, setFilterStatus
        }
    }, [filterEmp, setFilterEmp, filterDate, setFilterDate, filterStatus, setFilterStatus]);
    
    const summaryFilter = useMemo(() => {
        return {
            filterEmp, setFilterEmp, 
            filterDay, setFilterDay, 
            filterMonth, setFilterMonth, 
            filterYear, setFilterYear
        }
    }, [filterEmp, setFilterEmp, filterDay, setFilterDay, filterMonth, setFilterMonth, filterYear, setFilterYear]);

    const handleCheckedChange = (event) => {
        if (!checked) {
            setFilterEmp("");
            setFilterDate(null);
            setFilterStatus("");
        } else {
            setFilterEmp("");
            setFilterDay("");
            setFilterMonth(null);
            setFilterYear(null);
        }
        setChecked(event.target.checked);
    };

    const getFilters = (filters, key) => {
        const { 
            filterEmp, filterDate, filterStatus,
            filterDay, filterMonth, filterYear,
        } = filters || {};

        switch (key) {
            case "general":
                return { 
                    filterEmp: filterEmp || "", 
                    filterDate: filterDate ? dayjs(filterDate).format("YYYY-MM-DD") : null, 
                    filterStatus: filterStatus || ""
                };

            case "summary":
                return { 
                    filterEmp: filterEmp || "", 
                    filterDay: filterDay || "", 
                    filterMonth: filterMonth ? dayjs(filterMonth).get('month') + 1 : null,
                    filterYear: filterYear ? dayjs(filterYear).get('year') : null,
                };

            default: 
                return null;
        }
    }

    const fetchLeaveList = useCallback(async (page, filters, key, controller) => {

        console.log('filters => ', filters);
        console.log('key => ', key);

        const currentFilters = getFilters(filters, key);
        console.log('currentFilters => ', currentFilters);

        setCurrentPage(page);
        setLeaveList([]);
        setIsLoading(true);
            
        try {
            const data = await leaveService.getAllLeave({ page, filters: currentFilters, key }, controller.signal);
            console.log(data);

            if (data.result) {
                setLeaveList([...data.message]);
                setRowCount(data.rowCount);
            }
            
            setIsLoading(false);

        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }

    }, [])

    useEffect(() => {

        const ctrl = new AbortController();

        setController(ctrl);
        fetchLeaveList(0, null, "general", ctrl);

    }, [fetchLeaveList, checked]);

    useEffect(() => {

        return () => { controller && controller.abort(); }

    }, [controller]);

    const renderFilter = useMemo(() => {

        if (!checked) {
            return (
                <GeneralFilter 
                    rowCount={rowCount}
                    fetchLeaveList={fetchLeaveList}
                    filters={generalFilter}
                    controller={controller}
                />
            )
        } else {
            return (
                <SummaryFilter 
                    rowCount={rowCount} 
                    fetchLeaveList={fetchLeaveList}
                    filters={summaryFilter}
                    controller={controller}
                />
            )
        } 

    }, [checked, rowCount, fetchLeaveList, generalFilter, summaryFilter, controller]);

    return (
        <div className="leave-list">

            <div className="leave-header">
                <Typography className="header">รายการวันลาทั้งหมด</Typography>
                <FormControlLabel 
                    label="สรุปข้อมูลการลา"
                    labelPlacement="bottom"
                    control={(
                        <Switch 
                            checked={checked}
                            onChange={handleCheckedChange}
                        />
                    )} 
                />
            </div>

            {renderFilter}

            <DataTable 
                currentPage={currentPage}
                rows={leaveList} 
                rowCount={rowCount}
                columns={columns} 
                isLoading={isLoading} 
                fetchFunction={fetchLeaveList}
                filters={checked ? summaryFilter : generalFilter}
                keyCode={checked ? "summary" : "general"}
            />

        </div>
    );
}

export default LeaveList

function DetailButton({ leaveId }) {

    const navigate = useNavigate();

    const handleDetailClick = () => navigate(`leave-detail/${leaveId}`);

    return (
        <IconButton onClick={handleDetailClick}>
            <FindInPageOutlined className="detail-button" />
        </IconButton>
    )
}

function NoteButton({ reason }) {

    const [openDialog, setOpenDialog] = useState(false);
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    const handleClick = () => {
        handleOpenDialog();
    }

    if (!reason) {
        return <>-</>
    }

    return (
        <>
            <IconButton onClick={handleClick}>
                <ArticleOutlined className="note-button" />
            </IconButton>
            
            <Dialog fullWidth onClose={handleCloseDialog} open={openDialog}>
                <DialogTitle>
                    <Typography sx={{ fontSize: "20px", fontWeight: "var(--bold-weight)" }}>หมายเหตุ</Typography>
                </DialogTitle>
                <Divider variant="middle" />
                <List sx={{ pt: 0 }}>
                    <ListItem>
                        <ListItemText sx={{ pl: 2, pr: 2 }} primary={reason} />
                    </ListItem>
                </List>
            </Dialog>
        </>
    )
}

function Item({ children, width, className }) {
    return (
        <Box className={className} sx={{ width }}>
            {children}
        </Box>
    )
}

function GeneralFilter({ rowCount, fetchLeaveList, filters, controller }) {

    const key = useMemo(() => "general", []);

    const {
        filterEmp, setFilterEmp,
        filterDate, setFilterDate,
        filterStatus, setFilterStatus
    } = filters;

    const [openDatePicker, setOpenDatePicker] = useState(false);
    
    const handleOpenDatePicker = () => setOpenDatePicker(true);
    const handleCloseDatePicker = () => setOpenDatePicker(false);

    const handleChangeEmp = (e) => setFilterEmp(e.target.value);
    const handleChangeDate = (newDate) => setFilterDate(newDate);
    const handleChangeStatus = (e) => setFilterStatus(e.target.value);

    const handleFilterSearch = () => fetchLeaveList(0, filters, key, controller);

    const clearFilter = () => {
        setFilterEmp("");
        setFilterDate(null);
        setFilterStatus("");
        fetchLeaveList(0, null, key, controller);
    }

    const renderStatusValue = (val) => {
        if (val !== "") {
            const currentStatus = statusList.find(status => status.value === val);
            return currentStatus.name;
        }
        
        return "สถานะ : ทั้งหมด";
    }

    return (
        <Box className="options">
            <Typography className="text">จำนวน {rowCount} รายการ</Typography>

            <Item width="15%">
                <TextField 
                    fullWidth 
                    className="emp-input"
                    placeholder='พนักงาน : ทั้งหมด'
                    value={filterEmp} 
                    onChange={handleChangeEmp} 
                />
            </Item>

            <Item width="15%">
                <DesktopDatePicker
                    clearable
                    open={openDatePicker}
                    onOpen={handleOpenDatePicker}
                    onClose={handleCloseDatePicker}
                    inputFormat="DD MMMM BBBB"
                    mask=""
                    inputProps={{
                        placeholder: 'วันที่แจ้ง : ทั้งหมด',
                        readOnly: true
                    }}
                    components={{
                        OpenPickerIcon: ArrowDropDown
                    }}
                    value={filterDate}
                    onChange={handleChangeDate}
                    renderInput={(params) => (
                        <TextField 
                            className="date-picker" 
                            fullWidth 
                            onClick={handleOpenDatePicker}
                            {...params} 
                        />
                    )}
                />
            </Item>

            <Item width="15%">
                <Select
                    className="dropdown"
                    fullWidth
                    displayEmpty
                    value={filterStatus}
                    renderValue={renderStatusValue}
                    onChange={handleChangeStatus}
                >
                    <MenuItem value="">ทั้งหมด</MenuItem>
                    {
                        statusList.map((status) => (
                            <MenuItem key={status.value} value={status.value}>{status.name}</MenuItem>
                        ))
                    }
                </Select>
            </Item>
            
            <Item className="button" width="10%">
                <Button 
                    fullWidth 
                    variant="contained" 
                    className="filter-button"
                    onClick={handleFilterSearch}
                >
                    ค้นหา
                </Button>
                <Button 
                    fullWidth 
                    variant="contained" 
                    className="filter-button"
                    onClick={clearFilter}
                >
                    ล้าง
                </Button>
            </Item>
        </Box>
    )
}

function SummaryFilter({ rowCount, fetchLeaveList, filters, controller }) {

    const key = useMemo(() => "summary", []);

    const {
        filterEmp, setFilterEmp, 
        filterDay, setFilterDay, 
        filterMonth, setFilterMonth, 
        filterYear, setFilterYear
    } = filters;
    
    const handleChangeEmp = (e) => setFilterEmp(e.target.value);

    const [openMonthPicker, setOpenMonthPicker] = useState(false);
    
    const handleOpenMonthPicker = () => setOpenMonthPicker(true);
    const handleCloseMonthPicker = () => setOpenMonthPicker(false);

    const [openYearPicker, setOpenYearPicker] = useState(false);
    
    const handleOpenYearPicker = () => setOpenYearPicker(true);
    const handleCloseYearPicker = () => setOpenYearPicker(false);

    const handleFilterSearch = () => fetchLeaveList(0, filters, key, controller);

    const clearFilter = () => {
        setFilterEmp("");
        setFilterDay("");
        setFilterMonth(null);
        setFilterYear(null);
        fetchLeaveList(0, null, key, controller);
    }

    const renderValue = (option) => {
        if (option === "") return "วันที่ : ทั้งหมด";
      
        return option;
    }

    return (
        <Box className="options">
            <Typography className="text">จำนวน {rowCount} รายการ</Typography>

            <Item width="12%">
                <TextField 
                    fullWidth
                    className="emp-input"
                    placeholder='พนักงาน : ทั้งหมด'
                    value={filterEmp} 
                    onChange={handleChangeEmp} 
                />
            </Item>

            <Item width="12%">
                <Select
                    required
                    fullWidth
                    className="dropdown"
                    displayEmpty
                    value={filterDay}
                    onChange={({ target }) => setFilterDay(target.value)}
                    renderValue={renderValue}
                >
                    <MenuItem value="">ทั้งหมด</MenuItem>
                    {
                        days.map(day => (
                            <MenuItem key={day} value={day}>{day}</MenuItem>
                        ))
                    }
                </Select>
            </Item>

            <Item width="12%">
                <DesktopDatePicker
                    clearable
                    open={openMonthPicker}
                    onOpen={handleOpenMonthPicker}
                    onClose={handleCloseMonthPicker}
                    mask=""
                    inputFormat="MMMM"
                    views={["month"]}
                    value={filterMonth}
                    onChange={(month) => setFilterMonth(month)}
                    inputProps={{
                        placeholder: "เดือน : ทั้งหมด",
                        readOnly: true
                    }}
                    components={{
                        OpenPickerIcon: ArrowDropDown
                    }}
                    renderInput={(params) => (
                        <TextField 
                            className="date-picker" 
                            fullWidth 
                            onClick={handleOpenMonthPicker}
                            {...params} 
                        />
                    )}
                />
            </Item>

            <Item width="12%">
                <DesktopDatePicker
                    clearable
                    open={openYearPicker}
                    onOpen={handleOpenYearPicker}
                    onClose={handleCloseYearPicker}
                    inputFormat="BBBB"
                    views={["year"]}
                    value={filterYear}
                    onChange={(year) => setFilterYear(year)}
                    inputProps={{
                        placeholder: "ปี : ทั้งหมด",
                        readOnly: true
                    }}
                    components={{
                        OpenPickerIcon: ArrowDropDown
                    }}
                    renderInput={(params) => (
                        <TextField 
                            className="date-picker" 
                            fullWidth 
                            onClick={handleOpenYearPicker}
                            {...params} 
                        />
                    )}
                />
            </Item>
            
            <Item className="button" width="10%">
                <Button 
                    fullWidth 
                    variant="contained" 
                    className="filter-button"
                    onClick={handleFilterSearch}
                >
                    ค้นหา
                </Button>
                <Button 
                    fullWidth 
                    variant="contained" 
                    className="filter-button"
                    onClick={clearFilter}
                >
                    ล้าง
                </Button>
            </Item>
        </Box>
    )
}