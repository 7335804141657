import React from 'react'
import './CustomModal.css'

import { Box, Modal } from '@mui/material'

import ConfirmModal from './ConfirmModal';
import InputModal from './InputModal';
import ResponseModal from './ResponseModal';
import LoadingModal from './LoadingModal';

function CustomModal({ template, useModal, empName, onAccept, onDecline, taskAfterSuccess, taskAfterFailure }) {

    const {
        openModal,
        handleClose,
        isSuccess,
        contentType,
        modalType
    } = useModal;

    const tp = template[contentType];

    const onClose = () => {
        handleClose();

        if (isSuccess) taskAfterSuccess && taskAfterSuccess();
        else taskAfterFailure && taskAfterFailure();
    }

    const modal = () => {
        switch (modalType) {
            case 'confirm':
                return (
                    <ConfirmModal 
                        template={tp} 
                        empName={empName}
                        onAccept={onAccept} 
                        onDecline={onDecline} 
                    />
                );

            case 'input':
                return (
                    <InputModal 
                        onAccept={onAccept} 
                        onDecline={onDecline} 
                    />
                );

            case 'response':
                return (
                    <ResponseModal template={tp} />
                );

            case 'loading':
                return (
                    <LoadingModal />
                );

            default:
                break;
        }
    }

    return (
        <Modal
            open={openModal}
            onClose={modalType !== 'loading' ? onClose : null}
        >
            <Box 
                className="modal-container"
                sx={{ width: { xs: '70%', sm: '55%', md: '40%' } }}
            > 
                {modal()}
            </Box>
        </Modal>
    )
}

export default CustomModal