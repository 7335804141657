import React from 'react'

import { Navigate, Outlet } from 'react-router-dom';

import useAuth from '../../hooks/useAuth'

function PublicLayout() {
  
    const { auth } = useAuth();
    
    if (auth) {

        return <Navigate to="/" replace />

    } else {

        return <Outlet />;

    }
}

export default PublicLayout