import React from 'react'
import './ResponseModal.css'

import { Typography } from '@mui/material'

function ResponseModal({ template }) {
    return (
        <div className="response-modal">
            { 
                template.isMultiline ? (
                    <>
                        <Typography className="title">{template.text.split(' ')[0]}</Typography>
                        <Typography className="title">{template.text.split(' ')[1]}</Typography>
                    </>
                ) : (
                    <Typography className="title">{template.text}</Typography>
                )
            }
            <img src={template.img} width="100" alt="" />
        </div>
    )
}

export default ResponseModal