import { useAxios } from "../guard/axiosConfig";

export const leaveService = {
    getAllLeave,
    createLeave,
    getLeaveById,
    getLeaveByUsername,
    updateStatus,
    updateLeave,
}

async function getAllLeave(obj, signal) {
    const { data } = await useAxios.post('/leave/getAllLeave', obj, { signal });

    return data;
}

async function createLeave(leave) {
    const formData = new FormData();

    formData.append('data', JSON.stringify({
        ...leave,
        hours: Number(leave.hours),
    }))

    for (let index = 0; index < leave.images.length; index++) {
        const image = leave.images[index];
        formData.append('images', image)
    }

    const { data } = await useAxios.post('/leave/createLeave', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });

    return data;
}

async function getLeaveById(leaveId) {
    const { data } = await useAxios.post('/leave/getLeaveById', { leaveId });

    return data;
}

async function getLeaveByUsername(obj, signal) {
    const { data } = await useAxios.post('/leave/getLeaveByUsername', obj, { signal });

    return data;
}

async function updateStatus(leaveId, status, reason) {
    const { data } = await useAxios.post('/leave/updateStatus', { leaveId, status, reason });

    return data;
}

async function updateLeave(leave) {
    const formData = new FormData();

    formData.append('data', JSON.stringify({
        ...leave,
        hours: Number(leave.hours),
    }))

    for (let index = 0; index < leave.images.length; index++) {
        const image = leave.images[index];
        formData.append('images', image)
    }

    const { data } = await useAxios.post('/leave/updateLeave', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });

    return data;
}