import { createContext, useEffect, useState } from 'react'

export const AuthContext = createContext();

function AuthProvider({ children }) {

    const [auth, setAuth] = useState(JSON.parse(localStorage.getItem("user")) || null);

    useEffect(() => {

        // TODO: จำเป็นต้องเช็คด้วยเหรอ????
        
        if (!auth) {
            localStorage.clear("user");
        } else {
            localStorage.setItem("user", JSON.stringify({ ...auth }));
        }
        
    }, [auth]);

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider