import React, { useEffect, useState } from 'react'
import './DataTable.css'

import { Pagination, Stack } from '@mui/material'

import { 
    DataGrid, 
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector, 
} from '@mui/x-data-grid';

import Loading from '../Loading/Loading';

function DataTable({ currentPage, rows, rowCount, columns, isLoading, fetchFunction, filters, keyCode }) {

    const [controller, setController] = useState(null);

    const handlePageChange = (page) => {
        const ctrl = new AbortController();
        setController(ctrl);
        fetchFunction && fetchFunction(page, filters, keyCode, ctrl)
    };

    useEffect(() => {

        return () => { controller && controller.abort(); }

    }, [controller]);

    return (
        <div className="data-table">
            <div className="table-container">
                <DataGrid 
                    className="table"
                    disableColumnMenu
                    disableSelectionOnClick
                    loading={isLoading}
                    paginationMode="server"
                    pageSize={6}
                    rows={rows}
                    page={currentPage}
                    rowCount={rowCount}
                    columns={columns}
                    onPageChange={handlePageChange}
                    components={{ 
                        Pagination: CustomPagination, 
                        LoadingOverlay: Loading,
                        NoResultsOverlay: NoResult,
                        NoRowsOverlay: NoResult,
                    }} 
                />
            </div>
        </div>
    )
}

export default DataTable

function CustomPagination() {

    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    const handlePageChange = (e, val) => apiRef.current.setPage(val - 1);

    return (
        <Pagination 
            variant="outlined" 
            className="pagination"
            page={page + 1}
            count={pageCount} 
            onChange={handlePageChange} />
    )
}

function NoResult() {
    return (
        <Stack height="100%" alignItems="center" justifyContent="center">
            ไม่พบข้อมูล
        </Stack>
    )
}